DEF.database = {};
DEF.database.DT = {};

DEF.database.DT.Main = Backbone.Marionette.View.extend({
	className: 'database',
	id       : 'DATABASE',
	template : require('./templates/database_main.html'),
	regions  : {
		data  : '#data.main_content',
		filter: '#db_filter'
	},
	ui: {
		menuitem: '.menuitem'
	},
	events: {
		'click @ui.menuitem': 'Click'
	},
	Click(e) {
		APP.Route(`#DT/database/${e.currentTarget.id}`);
	},
	onRender() {
		switch (this.options.collection_name) {
		case 'data':
			if (APP.models.data.length < 10)
				setTimeout(() => {
					const where = { d: { $gt: Date.now() - (1000 * 60 * 10) } };
					console.log(where);
					APP.models.data._where(where).then(this.render.bind(this));
				}, 2500);
		}

		this.filter_view = new DEF.database.DT.Filter({
			collection     : this.options.collection,
			parent         : this,
			collection_name: this.options.collection_name
		});
		const filter = APP.Tools.store('filter', this.options.collection_name);
		if (filter)
			this.filter_view.filters = JSON.parse(filter);
		// this.data_view.filters = JSON.parse(filter);

		this.showChildView('filter', this.filter_view);

		this.listenTo(this.filter_view, 'filter', this.ApplyFilter);
		this.listenTo(this.filter_view, 'change_view', this.SetView);

		// _.defer(this.XonAttach.bind(this));
	},
	onDomRefresh() {
		this.SetView();
	},
	SetView() {
		switch (APP.Tools.store('dt', 'db_view') || 'table') {
		case 'spreadsheet':
			this.options.parent.ToggleDetails(false);
			this.data_view = new DEF.database.DT.Spreadsheet({
				collection     : this.options.collection,
				collection_name: this.options.collection_name,
				childView      : DEF.database.DT.cell,
				emptyView      : DEF.TG.Empty
			});
			break;
		case 'json':
			console.log('json init');
			this.options.parent.ToggleDetails(false);
			this.data_view = new DEF.database.DT.Json({
				collection     : this.options.collection,
				collection_name: this.options.collection_name,
				childView      : DEF.database.DT.null
			});
			break;
		case 'table':
			this.options.parent.ToggleDetails(true);
			this.data_view = new DEF.database.DT.Table({
				collection     : this.options.collection,
				collection_name: this.options.collection_name,
				childView      : DEF.database.DT.child,
				emptyView      : DEF.TG.Empty
			});
		}
		$(this.filter_view.ui.table).html(APP.Tools.icon('th-list'));
		$(this.filter_view.ui.json).html(APP.Tools.icon('align-left'));
		$(this.filter_view.ui.spreadsheet).html(APP.Tools.icon('table'));
		if (this.collection.at(0)) {
			const comparator = this.collection.at(0).db_comparator;
			if (comparator)
				this.data_view.viewComparator = comparator;
		}
		const filter = APP.Tools.store('filter', this.options.collection_name);
		if (filter)
			this.data_view.filters = JSON.parse(filter);
		console.log('shjow json');
		this.showChildView('data', this.data_view);
		console.log('end show');
	},
	onAttach() {
		console.log('attach');
		$('#db_menu').removeClass('current');
		$(`#db_menu #${this.options.collection_name}`).addClass('current');
		$(`#db_menu #${this.options.collection_name} .minwidth1600`).removeClass('minwidth1600');
	},
	ApplyFilter() { // the Filter view trigger a filter change, so re-render
		this.data_view.filters = this.filter_view.filters;
		this.data_view.render();
		APP.Tools.store('filter', this.options.collection_name, JSON.stringify(this.data_view.filters));
	}
});

/*

 ███████╗██╗██╗  ████████╗███████╗██████╗     ██████╗  █████╗ ██████╗
 ██╔════╝██║██║  ╚══██╔══╝██╔════╝██╔══██╗    ██╔══██╗██╔══██╗██╔══██╗
 █████╗  ██║██║     ██║   █████╗  ██████╔╝    ██████╔╝███████║██████╔╝
 ██╔══╝  ██║██║     ██║   ██╔══╝  ██╔══██╗    ██╔══██╗██╔══██║██╔══██╗
 ██║     ██║███████╗██║   ███████╗██║  ██║    ██████╔╝██║  ██║██║  ██║
 ╚═╝     ╚═╝╚══════╝╚═╝   ╚══════╝╚═╝  ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝


*/
DEF.database.DT.Filter = Backbone.Marionette.View.extend({
	template : false,
	className: 'noselect',
	filters  : {},
	ui       : {
		filter     : '.filter',
		tool       : '.tool',
		search     : '#search #searchbox',
		perpage    : '#perpage',
		view       : '#view .option',
		table      : '#view #table',
		json       : '#view #json',
		spreadsheet: '#view #spreadsheet'
	},
	events: {
		'click @ui.filter'  : 'ToggleFilter',
		'input @ui.search'  : 'UpdateFilters',
		'click @ui.tool'    : 'DoTool',
		'change @ui.perpage': 'SetPerPage',
		'click @ui.view'    : 'ChangeView'
	},
	fart() {
		console.log('fart');
	},
	render() {
		this.$el.html(this.GetFiltersHTML());
		_.defer(() => {
			$(this.ui.view).removeClass('selected');
			if (APP.Tools.store('dt', 'db_view'))
				$(this.ui[APP.Tools.store('dt', 'db_view')]).addClass('selected');
			else
				$(this.ui.table).addClass('selected');
		});
	},
	ChangeView(e) {
		const view = e.currentTarget.id;
		console.log(view);
		APP.Tools.store('dt', 'db_view', view);
		$(this.ui.view).removeClass('selected');
		$(this.ui[view]).addClass('selected').html(APP.Tools.icon('working'));
		_.delay(this.trigger.bind(this, 'change_view', this), 1); // give it a chance to show the working icon.  sometimes it does.
	},
	DoTool(e) {
		const cmd = $(e.currentTarget).data('cmd');
		console.log(`TOOL:${cmd}`);
		switch (cmd) {
		case 'DeployTags':
			APP.Tools.DeployTags();
			break;
		case 'KillOrphans':
			APP.Tools.KillOrphans();
			break;
		}
		this.render();
	},
	SetPerPage(e) {
		console.log(e.currentTarget.value);
		APP.Tools.store('db', 'perpage', e.currentTarget.value);
		this.trigger('filter', this);
	},
	construct_filter(filters, col, m) {
		const val = m.get(col);
		if (typeof val === 'object') // the perm object from Users, notably
			for (const v in val) {
				let key = `${col}.${v}`;
				key = col;
				if (!filters[key])
					filters[key] = {};
				filters[key][v] = false;
			}

		else
			filters[col][m.getUp(col)] = true;
	},
	GetFiltersHTML() {
		let col;
		this.$el.html('FART');
		const collection = this.options.collection;
		const model = collection.at(0);

		if (!model)
			return '';

		const filters = {};
		for (const c in model.db_filters) {
			col = model.db_filters[c];
			filters[col] = {};
			collection.each(this.construct_filter.bind(this, filters, col));
			// filters[col] = _.uniq(collection.pluck(col)); // TODO tbis doesn't support getUp.
		}

		let html = '';
		for (const f in filters) {
			html += `<div class='filter_row' id='${f}''>`;
			for (const v in filters[f]) {
				let name = v;
				let field = `${f}.${v}`;
				let value = true;
				if (filters[f][v]) {
					name = APP.Tools.strip_html_tags(this.options.collection.at(0).getf(f, v));
					field = f;
					value = v;
				}
				if (Number(name) === name)
					name = Number(name).toLocaleString();
				let state = 'off';
				if (this.filters[field])
					state = this.filters[field].indexOf(value) !== -1 ? 'on' : 'off';
				html += `<span class='filter ${f} ${state}' data-field='${field}' data-val='${value}'>${name}</span>`;
			}
			html += '</div>';
		}
		const tools = Object.keys(model.db_tools);
		html += "<div id='tools'>";
		html += "<div id='right_tools' class='pull-right'>";
		html += "<div id='search'>";
		html += `<input type='search' id='searchbox' value='${APP.Tools.store('database_search', this.options.collection_name) || ''}' placeholder='search'>`;
		html += '</div>';

		html += "<div id='view' class='switch'>";
		html += `<div class='option selected' id='table'>${APP.Tools.icon('th-list', 'Table View')}</div>`;
		html += `<div class='option' id='json'>${APP.Tools.icon('align-left', 'JSON view')}</div>`;
		html += `<div class='option' id='spreadsheet'>${APP.Tools.icon('table', 'Spreadsheet view')}</div>`;
		html += '</div>';

		// html += 'Per Page: ';
		// html += APP.UI.select_from_object('perpage', [10, 50, 100, 500, 1000, 10000], parseInt(APP.Tools.store('db', 'perpage')) || 50);
		html += '</div>';
		if (tools.length) {
			html += 'Tools:';
			for (const t in tools)
				html += `<span class='tool' data-cmd='${model.db_tools[tools[t]]}'>${tools[t]}</span>`;
		}
		html += '</div>';
		return html;
	},
	ToggleFilter(e) {
		const $el = $(e.currentTarget);
		const state = $el.hasClass('off');
		if (state)
			$el.removeClass('off');
		else
			$el.addClass('off');

		this.UpdateFilters();
	},
	UpdateFilters() {
		const filters = {};
		$('.filter:not(.off)').each((i, el) => {
			const field = $(el).data('field');
			const val = $(el).data('val');
			if (!filters[field])
				filters[field] = [];
			filters[field].push(`${val}`);
			filters[field].push(val);
		});
		if ($(this.ui.search).val())
			filters.search = $(this.ui.search).val();

		APP.Tools.store('database_search', this.options.collection_name, $(this.ui.search).val());
		this.filters = filters;
		this.trigger('filter', this);
	}
});

/*

 ███╗   ███╗ █████╗ ██╗███╗   ██╗██╗   ██╗██╗███████╗██╗    ██╗
 ████╗ ████║██╔══██╗██║████╗  ██║██║   ██║██║██╔════╝██║    ██║
 ██╔████╔██║███████║██║██╔██╗ ██║██║   ██║██║█████╗  ██║ █╗ ██║
 ██║╚██╔╝██║██╔══██║██║██║╚██╗██║╚██╗ ██╔╝██║██╔══╝  ██║███╗██║
 ██║ ╚═╝ ██║██║  ██║██║██║ ╚████║ ╚████╔╝ ██║███████╗╚███╔███╔╝
 ╚═╝     ╚═╝╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝  ╚═══╝  ╚═╝╚══════╝ ╚══╝╚══╝


*/
DEF.database.DT.Mainview = Backbone.Marionette.CollectionView.extend({
	ui: {
		sort: '.sort'
	},
	events: {
		'click @ui.sort': 'SetSort'
	},
	viewFilter (m) {
		if (m.model) // the whole filter/viewFilter debacle
			m = m.model
		let rs = false;
		if (!this.filters)
			return true;
		for (const f in this.filters) {
			const keys = this.filters[f];
			if (f === 'search') {
				const terms = keys.toUpperCase().split(',');
				const string = m.getSearch();
				rs = false;
				for (const t of terms)
					if (t)
						rs = rs || string.indexOf(t) !== -1;
				if (!rs)
					return false;
			} else if (keys.indexOf(`${m.getUp(f)}`) === -1) { return false; }
		}
		return true;
	},
	viewComparator (a, b) {
		a = a.model;
		b = b.model;
		const sortstring = APP.Tools.store('dbsort', this.options.collection_name) || false;
		if (sortstring) {
			const fields = sortstring.split('&');
			for (const sort of fields)
				if (sort) {
					const [field, dir] = sort.split('|');
					const fielda = a.getUp(field);
					const fieldb = b.getUp(field);
					if (dir === 'a') {
						if (fielda < fieldb)
							return -1;
						if (fieldb < fielda)
							return 1;
					}
					if (dir === 'd') {
						if (fielda > fieldb)
							return -1;
						if (fieldb > fielda)
							return 1;
					}
				}
		}
		return 0;
	},
	SetSort(e) {
		let sort = APP.Tools.store('dbsort', this.options.collection_name) || '';
		const col = e.currentTarget.id;
		if (sort.indexOf(col) === -1)
			sort = `${col}|a&${sort}`; // add the field
		else if (sort.indexOf(`${col}|a&`) !== -1)
			sort = sort.replace(`${col}|a&`, `${col}|d&`); // invert the order
		else
			sort = sort.replace(`${col}|d&`, '');

		APP.Tools.store('dbsort', this.options.collection_name, sort);
		this.render();
	}

});

/*

 ███████╗██████╗ ██████╗ ███████╗ █████╗ ██████╗ ███████╗██╗  ██╗███████╗███████╗████████╗
 ██╔════╝██╔══██╗██╔══██╗██╔════╝██╔══██╗██╔══██╗██╔════╝██║  ██║██╔════╝██╔════╝╚══██╔══╝
 ███████╗██████╔╝██████╔╝█████╗  ███████║██║  ██║███████╗███████║█████╗  █████╗     ██║
 ╚════██║██╔═══╝ ██╔══██╗██╔══╝  ██╔══██║██║  ██║╚════██║██╔══██║██╔══╝  ██╔══╝     ██║
 ███████║██║     ██║  ██║███████╗██║  ██║██████╔╝███████║██║  ██║███████╗███████╗   ██║
 ╚══════╝╚═╝     ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝   ╚═╝


*/
DEF.database.DT.Spreadsheet = DEF.database.DT.Mainview.extend({
	childViewContainer: '#data',
	template          : require('./templates/spreadsheet.html'),
	templateContext() {
		const model = this.collection.at(0);
		if (model)
			return {
				cols      : model.db_spreadsheet,
				collection: model.collection_name,
				datalists : this.GetDatalists()
			};

		return { cols: [], collection: '', datalists: '' };
	},
	tagName  : 'table',
	className: 'table table-full table-top spreadsheet_view database_view',
	childViewOptions() {
		return { collection_name: this.options.collection_name };
	},
	GetDatalists() {
		let out = '';
		const lists = {
			poller_id: 'pollers',
			screen_id: 'screens'
		};

		for (const id in lists) {
			const collection = lists[id];
			out += `<datalist id='spreadsheet_${id}'>`;
			const models = APP.models[collection].models;
			for (const m in models) {
				const model = models[m];
				out += `<option value='${model.id}'>${model.getName()}</option>`;
			}

			out += '</datalist>';
		}

		return out;
	}
});

/*

      ██╗███████╗ ██████╗ ███╗   ██╗
      ██║██╔════╝██╔═══██╗████╗  ██║
      ██║███████╗██║   ██║██╔██╗ ██║
 ██   ██║╚════██║██║   ██║██║╚██╗██║
 ╚█████╔╝███████║╚██████╔╝██║ ╚████║
  ╚════╝ ╚══════╝ ╚═════╝ ╚═╝  ╚═══╝


*/
DEF.database.DT.Json = DEF.database.DT.Mainview.extend({
	template : require('./templates/json.html'),
	className: 'json_view database_view',
	ui       : {
		save  : '#controlbox #save',
		revert: '#controlbox #revert',
		data  : '#data',
		json  : '.json'
	},
	events: {
		'keyup @ui.json'  : 'ValidateJSON',
		'click @ui.save'  : 'Save',
		'click @ui.revert': 'Revert'
	},
	onRender() {
		this.Revert();
	},

	ValidateJSON() {
		try {
			JSON.parse(this.ui.json.val());
			this.ui.json.removeClass('badjson');
			this.ui.save.removeClass('disabled');
		} catch (e) {
			this.ui.json.addClass('badjson');
			this.ui.save.addClass('disabled');
		}
	},

	getJSON() {
		return JSON.stringify(this.options.collection.filter(this.viewFilter.bind(this)), null, '\t');
	},
	Revert() {
		this.ui.data.val(this.getJSON());
		this.ValidateJSON();
	},
	Save() {
		const collection = this.options.collection;
		let id;
		let model;
		const json = JSON.parse(this.ui.data.val());
		console.log(json);
		// collection.add(json, { merge: true });  // highway does not support JSON
		for (const j in json) {
			id = json[j]._id;
			if (id) {
				model = collection.get(id);
				if (model)
					model.set(json[j]);
				else
					console.warn('id not found.  skipping this record.', id, json[j]);
			} else {
				model = new DEF[this.options.collection_name].Model(json[j]);
				console.log('new model', model.getName());
				collection.add(model);
			}
		}
		this.Revert();
	}
});

/*

 ████████╗ █████╗ ██████╗ ██╗     ███████╗
 ╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝
    ██║   ███████║██████╔╝██║     █████╗
    ██║   ██╔══██║██╔══██╗██║     ██╔══╝
    ██║   ██║  ██║██████╔╝███████╗███████╗
    ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝


*/
DEF.database.DT.Table = DEF.database.DT.Mainview.extend({
	childViewContainer: '#data',
	template          : require('./templates/table.html'),
	templateContext() {
		const model = this.collection.at(0);
		if (model)
			return {
				cols      : model.db_columns,
				collection: model.collection_name
			};

		return { cols: [], collection: '' };
	},
	tagName  : 'table',
	className: 'table table-full table-top table_view database_view',
	childViewOptions() {
		return { collection_name: this.options.collection_name };
	},

	/**
	 * Override this to try and not draw a million records - in "data" view mostly.
	 */
	addChildView() {
		const MAX_COUNT = 100;
		while (this.children.length >= MAX_COUNT)
			this.removeChildView(this.children.findByIndex(0));
		Backbone.Marionette.CollectionView.prototype.addChildView.apply(this, arguments);
	}
});

// this dumb thing is so JSON view can extend the same CollectionView used by TABLE and SPREADSHEET
// It doesn't show individual records, because it's all in the huge JSON div.
DEF.database.DT.null = Backbone.Marionette.View.extend({
	template: _.template('')
});

DEF.database.DT.cell = Backbone.Marionette.View.extend({
	tagName  : 'tr',
	className: 'databases',
	template : require('./templates/database_cell.html'),
	templateContext() {
		return {
			collection: this.model.collection_name,
			cols      : this.model.db_spreadsheet,
			fields    : this.GetFields(),
			icon      : this.model.getIcon(),
			model_name: this.model.getName()
		};
	},
	modelEvents: {
		change: 'render'
	},
	ui: {
		model_link: '.model_link',
		cell      : 'input'
	},
	events: {
		'click @ui.model_link': 'GoToModel',
		'change @ui.cell'     : 'SaveField',
		'keyup @ui.cell'      : 'CheckKey'
	},
	onRender() {
		$(this.el).attr('id', this.model.id);
	},
	GoToModel(e) {
		APP.Route($(e.currentTarget).data('link'));
		e.stopPropagation();
	},
	CheckKey(e) {
		const column = $(`.cell.${e.currentTarget.id}`);
		switch (e.key) {
		case 'ArrowUp':
			column[APP.Format.wrap(this._get_row(column) - 1, 0, column.length)].focus();
			break;
		case 'ArrowDown':
			column[APP.Format.wrap(this._get_row(column) + 1, 0, column.length)].focus();
			break;
		}
	},
	_get_row(column) {
		for (const c in column)
			if ($(column[c]).data('id') === this.model.get('_id'))
				return Number(c);
		return false;
	},
	SaveField(e) {
		const id = e.currentTarget.id;
		let val = e.currentTarget.value;
		if (val === 'false')
			val = false;
		if (val === 'true')
			val = true;
		if (isNumber(val))
			val = Number(val);
		console.log(id, val, this.model.getName());
		this.model.set(id, val);
	},
	FormatAttributes() {
		const out = {};
		for (let c = 0; c < this.model.db_spreadsheet.length; c++) {
			const col = this.model.db_spreadsheet[c];
			const val = this.model.get(col);
			out[col] = val;
		}
		return out;
	},
	GetFields() {
		let nots;
		let normals;
		let warnings;
		let alarms;
		let offs;
		let ons;
		const out = [];
		const cols = this.model.db_spreadsheet;
		for (const c in cols) {
			const col = cols[c];
			let val = this.model.get(col);
			let list = '';
			if (col.substr(-3) === '_id')
				list = `list='spreadsheet_${col}'`;
			switch (col) {
			case 'notifications':
				nots = this.model.get('notifications') || [];
				switch (this.model.getUp('type')) {
				case 'number':
					normals = nots.normal || { sms: [] };
					alarms = nots.alarm || { sms: [] };
					warnings = nots.warning || { sms: [] };
					console.log(nots);
					out[c] = `${APP.Tools.icon('normal')}:${normals.sms.length} `;
					out[c] += `${APP.Tools.icon('warning')}:${warnings.sms.length} `;
					out[c] += `${APP.Tools.icon('alarm')}:${alarms.sms.length} `;
					break;
				case 'boolean':
					offs = nots.off || { sms: [] };
					ons = nots.on || { sms: [] };
					console.log(nots);
					out[c] = `${APP.Tools.icon('off')}:${offs.sms.length} ${APP.Tools.icon('on')}:${ons.sms.length}`;
					break;
				default:
					out[c] = '';
				}
				break;
			case 'icon':
			case 'glyph':
			case 'image_url':
				out[c] = `<input ${list} data-id='${this.model.id}' class='cell ${col}' id='${col}' value='${val}'></input>`;
				// eslint-disable-next-line no-template-curly-in-string
				out[c] += APP.UI.fontawesome(col, val, `#${col}[data-id=\\"${this.model.id}\\"]`, "class='cell ${col}'");
				break;
			case 'color':
				out[c] = `<input ${list} type='text' data-id='${this.model.id}' class='cell ${col}' id='${col}' value='${val}'></input>`;
				out[c] += `<input ${list} type='color' data-id='${this.model.id}' class='cell ${col}' id='${col}' value='${val}'></input>`;
				break;
			default:
				if (val === undefined)
					val = '';
				out[c] = `<input ${list} data-id='${this.model.id}' class='cell ${col}' id='${col}' value='${val}'></input>`;
			}
		}
		return out;
	}
});

DEF.database.DT.child = Backbone.Marionette.View.extend({
	tagName  : 'tr',
	className: 'databases',
	template : require('./templates/database_child.html'),
	templateContext() {
		return {
			collection: this.model.collection_name,
			cols      : this.model.db_columns,
			attr      : this.FormatAttributes(),
			icon      : this.model.getIcon(),
			model_name: this.model.getName()
		};
	},
	modelEvents: {
		change: 'render'
	},
	ui: {
		model_link: '.model_link'
	},
	events: {
		'click @ui.model_link': 'GoToModel',
		click                 : 'Route'
	},
	onRender() {
		$(this.el).attr('id', this.model.id);
	},
	GoToModel(e) {
		APP.Route($(e.currentTarget).data('link'));
		e.stopPropagation();
	},
	Route() {
		const prefix = APP.design_time ? '#DT/database/' : '#';
		APP.Route(`${prefix + this.options.collection_name}/${this.model.id}`);
	},
	FormatAttributes() {
		const out = {};
		for (let c = 0; c < this.model.db_columns.length; c++) {
			const col = this.model.db_columns[c];
			const val = this.model.getf(col);
			out[col] = val;
		}
		return out;
	}
});


/*

 ██████╗ ███████╗████████╗ █████╗ ██╗██╗     ███████╗
 ██╔══██╗██╔════╝╚══██╔══╝██╔══██╗██║██║     ██╔════╝
 ██║  ██║█████╗     ██║   ███████║██║██║     ███████╗
 ██║  ██║██╔══╝     ██║   ██╔══██║██║██║     ╚════██║
 ██████╔╝███████╗   ██║   ██║  ██║██║███████╗███████║
 ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝╚══════╝


*/
DEF.database.DT.Details = Backbone.Marionette.View.extend({
	className: 'database',
	id       : 'DATABASE',
	template : require('./templates/database_details.html'),
	templateContext() {
		return {
			model_name: this.model.getName() || `${APP.Tools.icon('plus')} NEW MODEL`
		};
	},
	ui: {
		json    : 'textarea',
		commands: '#details_footer .link'
	},
	events: {
		'keyup @ui.json'    : 'Validate',
		'click @ui.commands': 'Command'
	},
	modelEvents: {
		change: 'TestRender'
	},
	onRender() {
		// APP.root.getRegion('main').currentView.ToggleDetails(true);
		this.Revert();
		// $("#data > table tr.databases").removeClass("active");
		// $("#data > table tr.databases#" + this.model.id).addClass("active");
	},
	TestRender() {
		if (!$(this.ui.json).is(':focus'))
			this.render();
	},
	Revert() {
		this.ui.json.val(JSON.stringify(this.model.toJSON(), null, '\t'));
	},
	Validate() {
		try {
			JSON.parse(this.ui.json.val());
			this.ui.json.removeClass('badjson');
		} catch (e) {
			this.ui.json.addClass('badjson');
		}
	},
	Save() {
		const attr = JSON.parse(this.ui.json.val());
		if (attr) {
			console.log('replace document', attr);
			if (this.model.id) {
				this.model.clear({ silent: true }).set(attr);
			} else {
				delete attr._id;
				APP.models[this.model.collection_name].create(attr);
			}
		}
	},
	Command(e) {
		const cmd = e.currentTarget.id;
		console.log(cmd);
		switch (cmd) {
		case 'save':
			this.Save();
			this.Revert();
			break;
		case 'delete':
			if (window.confirm('Are you sure you want to delete this record?'))
			// APP.models[this.model.collection_name].remove(this.model);
				this.model.destroy();

			break;
		case 'revert':
			this.Revert();
			break;
		}
	}
});
