module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+=''+
((__t=(APP.UI.tabs("trace","tags",{tags:"Tags",opt:"Options"},false,"none") ))==null?'':__t)+
'\n';


function save_color(id) {
	console.log("color", id);
}

__p+='\n<div class=\'tabcontent\' data-for="trace" id=\'tags\'>\n	<table>\n		<tr>\n			<th>Tags</th>\n			<td>\n				'+
((__t=(APP.UI.chooser("tag_ids", "tags", tag_ids,"multi", {})))==null?'':__t)+
'\n			</td>\n		</tr>\n	</table>\n</div>\n<div class=\'tabcontent\' data-for="trace" id=\'opt\'>\n	<table>\n		<tr>\n			<th>Tag</th>\n			<th>Color</th>\n			<th>Dash</th>\n			<th>Thickness</th>\n			<th>Step</th>\n		</tr>\n		';


		for (var i in tag_ids) {
			var tag_id  = tag_ids[i];
			var opt = traces[tag_id] || { color: "x", dash:"y"};
			if (!APP.models.tags.get(tag_id))
				continue;
		
__p+='\n		<tr>\n			<td>\n				'+
((__t=(APP.models.tags.get(tag_id).getName()))==null?'':__t)+
'\n			</td>\n			<td>\n				<!-- '+
((__t=(APP.UI.input("traces."+tag_id+".color", opt.color,"color")))==null?'':__t)+
' -->\n				<input tagid="'+
((__t=(tag_id))==null?'':__t)+
'"\n					onChange="APP.models.tags.get(this.getAttribute(\'tagid\')).set(\'color\', this.value)" type="color"\n					id="'+
((__t=(" traces. "+tag_id+".color "))==null?'':__t)+
'" value="'+
((__t=(APP.models.tags.get(tag_id).get('color')))==null?'':__t)+
'"\n					class="draw">\n			</td>\n			<td>\n				'+
((__t=(APP.UI.select_from_object("traces."+tag_id+".dash", ["solid","dashed","dotted"],opt.dash)))==null?'':__t)+
'\n			</td>\n			<td>\n				'+
((__t=(APP.UI.slider("traces."+tag_id+".width", opt.width,1,10,0.1,"field live")))==null?'':__t)+
'\n			</td>\n			<td>\n				'+
((__t=(APP.UI.checkbox("traces."+tag_id+".step", opt.step," ","Draw trace as steps","field live")))==null?'':__t)+
'\n			</td>\n		</tr>\n		<!-- <tr>\n			<th>Options</th>\n			<td>\n				'+
((__t=(APP.UI.chooser("tag_ids", "tags", tag_ids,"multi")))==null?'':__t)+
'\n			</td>\n		</tr> -->\n		';

		}
		
__p+='\n	</table>\n</div>\n<table>\n	<tr>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'autoscale\' value=\'true\' '+
((__t=(autoscale? "checked" : "" ))==null?'':__t)+
' class=\'field live\'>\n			Autoscale\n			<input type=\'checkbox\' id=\'zero\' value=\'true\' '+
((__t=(zero? "checked" : "" ))==null?'':__t)+
' class=\'field live\'> Include Zero\n			<input type=\'checkbox\' id=\'legend\' value=\'true\' '+
((__t=(legend? "checked" : "" ))==null?'':__t)+
' class=\'field draw\'> Draw Legend\n			<input type=\'checkbox\' id=\'hover\' value=\'true\' '+
((__t=(hover? "checked" : "" ))==null?'':__t)+
' class=\'field draw\'> Show cursor\n		</td>\n	</tr>\n	<tr>\n		<th>Title</th>\n		<td>\n			'+
((__t=(APP.UI.input("title",title, "title", "Trend title", "field draw")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Chart Duration</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("chart_duration", {"300":"5 Minutes","1800":"Half Hour","3600":"1 Hour","7200":"2 Hours","21600":"6 Hour","86400":"1 Day","172800":"2 Days"}, chart_duration, "field draw") ))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Overview Duration</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("overview_duration", {"-1":"none","3600":"1 Hour","86400":"1 Day","172800":"2 Days","604800":"1 Week","1209600":"2 Weeks"}, overview_duration, "field draw refresh") ))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Trace Smooth</th>\n		<td>\n			<input type="range" min=0 max=1 step=0.01 value=\''+
((__t=(smooth))==null?'':__t)+
'\' id=\'smooth\' class=\'field live\'\n				list=\'tickmarks\'>\n			<datalist id=tickmarks>\n				<option>0.5</option>\n				<option>1</option>\n			</datalist>\n		</td>\n	</tr>\n</table>\n<fieldset>\n	<legend>Data Smoothing</legend>\n	<table>\n		<tr>\n			<th>Smoothing Method</th>\n			<td>\n				'+
((__t=(APP.UI.select_from_object("smooth_method",{"none":"None","mean":"Moving Average","exponential":"Exponential","bestval":"Best Value"}, smooth_method)))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<th>Chart Smooth</th>\n			<td>\n				<input type="range" min=0 max=100 step=1 value=\''+
((__t=(chart_smooth))==null?'':__t)+
'\' id=\'chart_smooth\' class=\'field live\'\n					list=\'tickmarks2\'>\n				<datalist id=tickmarks2>\n					<option>5</option>\n					<option>10</option>\n				</datalist>\n			</td>\n		</tr>\n		<tr>\n			<th>Overview Smooth</th>\n			<td>\n				<input type="range" min=0 max=100 step=1 value=\''+
((__t=(overview_smooth))==null?'':__t)+
'\' id=\'overview_smooth\'\n					class=\'field '+
((__t=(overview_duration==-1?"disabled":""))==null?'':__t)+
'\' list=\'tickmarks2\'>\n			</td>\n		</tr>\n\n	</table>\n</fieldset>\n\n<table>\n	<tr>\n		<td colspan=5>\n			<hr>\n		</td>\n	</tr>\n</table>\n<fieldset>\n	<legend>Experimental</legend>\n	<table>\n		<tr>\n			<th>Date Range Squishing</th>\n			<td>\n				<input type="range" min=1 max=10 step=0.1 value=\''+
((__t=(date_pow))==null?'':__t)+
'\' id=\'date_pow\' class=\'field live\'>\n			</td>\n		</tr>\n		<tr>\n			<th>Value Range Squishing</th>\n			<td>\n				<input type="range" min=0.2 max=2.5 step=0.01 value=\''+
((__t=(val_pow))==null?'':__t)+
'\' id=\'val_pow\' class=\'field live\'\n					list=\'tickmarksvalpow\'>\n				<datalist id=tickmarksvalpow>\n					<option>1</option>\n				</datalist>\n			</td>\n		</tr>\n		<tr>\n			<th>Complexity</th>\n			<td>\n				<input type="range" min=0.00 max=5 step=0.01 value=\''+
((__t=(complexity))==null?'':__t)+
'\' id=\'complexity\' class=\'field live\'>\n				<datalist id=tickmarksvalpow>\n					<option>1</option>\n				</datalist>\n			</td>\n		</tr>\n		<tr>\n			<th></th>\n			<td>\n				<input type=\'checkbox\' id=\'predict\' value=\'true\' '+
((__t=(predict? "checked" : "" ))==null?'':__t)+
' class=\'field\'> Predictive\n				Traces\n\n			</td>\n		</tr>\n		<tr>\n			<th></th>\n			<td>\n				<input type=\'checkbox\' id=\'link\' value=\'true\' '+
((__t=(link? "checked" : "" ))==null?'':__t)+
' class=\'field\'> Link to other\n				Trends\n\n			</td>\n		</tr>\n		<tr>\n			<th></th>\n			<td>\n				<input type=\'checkbox\' id=\'translucent\' value=\'true\' '+
((__t=(translucent? "checked" : "" ))==null?'':__t)+
' class=\'field\'> Draw traslucent trends\n\n			</td>\n		</tr>\n\n	</table>\n</fieldset>\n';
}
return __p;
};
