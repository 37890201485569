DEF.devices.DT = {};
DEF.devices.DT.Main = Backbone.Marionette.CollectionView.extend({
	className: 'devices',
	id       : 'DEVICES'
});
DEF.devices.settings = {
	modbustcp: require('../../pollers/modbustcp/views/device.js'),
	modbus   : require('../../pollers/modbus/views/device.js'),
	telegauge: require('../../pollers/telegauge/views/device.js'),
	bacnet: require('../../pollers/bacnet/views/device.js')
};
DEF.devices.DT.child = Backbone.Marionette.View.extend({
	className: 'device',
	template : require('./templates/device.html'),
	templateContext() {
		const css = this.model.all_fields();
		return css;
	},
	regions: {
		header: '#header',
		tags  : '#tags',
		status: '#status_box'
	},
	ui: {
		header : '#header',
		add    : '#devicemenu #add',
		edit   : '#devicemenu #edit',
		expand : 'span#expand',
		tag_box: '#tag_box',
		menu   : '#devicemenu',
		online : '#online'
	},
	events: {
		'click @ui.add'   : 'CreateTag',
		'click @ui.edit'  : 'ShowDetails',
		'click @ui.expand': 'Expand'
	},
	modelEvents: {
		'change:prefix change:connection': 'render',
		'change:online'                  : 'UpdateOnline',
		'change:enabled'                 : 'UpdateEnabled',
		'change:prefix'                  : 'ChangePrefix'
	},
	ChangePrefix() {
		APP.Tools.DeployTags();
	},
	initialize() {
		const dl = this.model.getDeviceLibrary();
		if (dl)
			this.listenTo(dl, 'change:image_url', this.render); // I wonder why this renders, but doesnt change anything
	},
	onRender() {
		const dl_id = this.model.get('dl_id');
		const d_id = this.model.id;

		const hide_type = APP.Tools.store('filter', dl_id) || [];
		let filter_search = APP.Tools.store('filtersearch', dl_id);
		if (hide_type.indexOf('search') > -1 || !filter_search)
			filter_search = false;
		// console.log('devicerender', this.model.getName());
		const view = new DEF.tags.DT.Tags({
			collection: APP.models.tags,
			childView : DEF.tags.DT.Tag,
			viewComparator(m) {
				return m.model.getUp('name');
			},
			viewFilter (m) {
				m = m.model;
				let rs = false;
				if (m.get('d_id') !== d_id)
					return false;
				if (hide_type.indexOf(m.getUp('type')) !== -1) 
					return false;
				if (hide_type.indexOf('formula') >= 0) {
					// console.log(m.getName(), m.getUp('formula'))
					if (!(m.getUp('formula') && m.getUp('formula').length > 0))
						return false;
				}
				if (filter_search) {
					// const fields = [m.getUp('name'), m.getUp('address'), m.getUp('tag_name')];
					// const search = fields.join(' ').toUpperCase();
					const terms = filter_search.toUpperCase().split(',');
					const search = m.getSearch();
					rs = false;
					for (const t of terms)
						if (t.trim())
							rs = rs || search.indexOf(t.trim()) !== -1;
					if (!rs)
						return false;
				}

				return true;
			}
		});
		this.showChildView('tags', view);

		// if (this.model.getUp('protocol') && this.model.getUp('protocol') !== 'none') {
		// 	const status = new DEF.devices.DT.Status({ model: this.model });
		// 	this.showChildView('status', status);
		// }

		if (APP.Tools.store('expand', this.model.id) === 'hide') {
			this.ui.tag_box.hide();
			this.ui.menu.hide();
			this.ui.expand.html(APP.Tools.icon('collapse'));
		}

		$(this.el).attr('id', this.model.id);

		this.UpdateOnline();
		this.UpdateEnabled();
	},
	CreateTag() {
		const library = { dl_id: this.model.get('dl_id'), symbol: 'TAG1' };
		let i = 1;
		while (APP.models.tag_library.where(library).length !== 0) {
			i++;
			library.symbol = `TAG${i}`;
		}
		library.name = `Tag ${i}`;
		const tag_library = APP.models.tag_library.create(library);
		this.listenToOnce(APP.models.tag_library, 'update', APP.Tools.DeployTags);
		// this.listenToOnce(APP.models.tag_library, "add", function _add(m) { // #1.2.18 - should be "sync", without no timeouts
		// 	_.delay(function(m) {
		// 		APP.Tools.DeployTags();
		// 		APP.Route("#DT/tags/" + m.id);
		// 	}.bind(this, m), 1000);
		// });
		const int = setInterval(() => { // watch for the new tag to show up, and route to it.
			const tl_id = tag_library.get('_id');
			if (tl_id) {
				const tag = APP.models.tags.find({
					tl_id,
					d_id: this.model.id
				});
				if (tag && tag.get('_id')) {
					clearInterval(int);
					APP.Route(`#DT/tags/${tag.get('_id')}`);
				}
			}
		}, 10);
	},
	Command(e) {
		switch (e.currentTarget.id) {
		case 'showtags':
			this.ShowDetails();
			break;
		case 'addtags':
			this.CreateTag();
			break;
		}
	},
	UpdateOnline() {
		if (this.model.get('online')) {
			$(this.ui.online).removeClass('offline').addClass('online');
			this.$el.removeClass('offline').addClass('online');
		} else {
			$(this.ui.online).removeClass('online').addClass('offline');
			this.$el.removeClass('online').addClass('offline');
		}
	},
	UpdateEnabled() {
		if (!this.model.get('enabled')) {
			$(this.el).addClass('disabled');
			this.model.set({ status: 'disabled', online: false });
		} else {
			$(this.el).removeClass('disabled');
		}
	},
	ShowDetails(e) {
		APP.Route(`#DT/devices/${this.model.id}`);
		// eslint-disable-next-line no-restricted-globals
		e.stopPropagation();
	},
	Expand(event) {
		const visible = $(this.ui.tag_box).is(':visible');
		APP.Tools.store('expand', this.model.id, visible ? 'hide' : 'show');
		this.ui.tag_box.slideToggle(100);
		this.ui.menu.toggle(100);
		this.ui.expand.html(APP.Tools.icon(visible ? 'collapse' : 'expand'));
		event.stopPropagation(); // prevent the parent "#expand" from firing
	}
});

DEF.devices.DT.Status = Backbone.Marionette.View.extend({
	id      : 'status',
	template: require('./templates/status_line.html'),
	templateContext() {
		const all = this.model.all_fields();
		all.icon = all.icon || '';
		return all;
	},
	modelEvents: {
		'change:poller_id'   : 'render',
		'change:status'      : 'render',
		'change:enabled'     : 'ChangeEnabled',
		'change:state'       : 'ChangeState',
		'change:next_poll'   : 'ChangeNext',
		'change:polling_rate': 'ChangePollingRate'
	},
	ui: {
		state   : '#state',
		next    : '#next',
		timeleft: '#timeleft',
		spinner : '#spinner'
	},
	onRender() {
		if (typeof this.model.get('poller_id') !== 'string') {
			this.$el.html('');
		} else {
			const polling_rate = this.model.getUp('polling_rate');
			const refresh = Math.max(100, polling_rate / 5);
			//			console.log('Ref', refresh);
			if (this.timer)
				clearInterval(this.timer);
			this.timer = setInterval(this.UpdateTimeLeft.bind(this), refresh);
			this.UpdateTimeLeft();
		}
	},
	// onRender: function () {
	// 	this.UpdateTimeLeft();
	// },
	onDestroy() {
		clearInterval(this.timer);
	},
	UpdateTimeLeft() {
		const timeleft = this.model.get('next_poll') - Date.now() + 3000;
		const notime = (timeleft < 1000);

		$(this.ui.timeleft).val(timeleft);
		if (notime !== this.last_notime)
			if (!notime)
				$(this.ui.timeleft).attr({ max: timeleft });

		this.last_notime = notime;
	},
	ChangeEnabled() {
		this.ui.timeleft.css({ display: this.model.getUp('enabled') ? 'block' : 'none' });
		this.model.set({ state: this.model.getUp('enabled') ? 'IDLE' : 'DISABLED' });
	},
	ChangeState() {
		$(this.ui.state).html(this.model.get('state').replace('* ', ''));
		if (this.model.get('online'))
			$(this.ui.spinner).addClass('fa-pulse');
		else
			$(this.ui.spinner).removeClass('fa-pulse');

		this.UpdateTimeLeft();
	},
	ChangeNext() {
		// if (this.model.getUp('polling_rate') > 5) {
		$(this.ui.next).html(APP.Format.livetime(this.model.get('next_poll')));
		this.UpdateTimeLeft();
		// }
	},
	ChangePollingRate() {
		$(this.ui.timeleft).attr('max', this.model.getUp('polling_rate'));
	}
});

DEF.devices.DT.tools = Backbone.Marionette.View.extend({
	template: require('./templates/device_tools.html')
});

DEF.devices.DT.Details = Backbone.Marionette.View.extend({
	id      : 'DEVICES',
	template: require('./templates/device_details_layout.html'),
	regions : {
		device  : '#device_details',
		settings: '#settings',
		library : '#device_library_details'
	},
	onRender() {
		const ld = new DEF.device_library.DT.Details({
			model: this.model.getDeviceLibrary()
		});
		this.showChildView('library', ld);

		const dd = new DEF.devices.DT.device_details({
			model: this.model
		});
		this.showChildView('device', dd);
	}
});

DEF.devices.DT.device_details = DEF.TG.Details.extend({
	template: require('./templates/device_details.html'),
	templateExtras() {
		return { blocks: this.model.getUp('blocks'), blocks_status: {} };
	},
	regions: {
		options: '#options'
	},
	initialize() {
		this.model.on('change:enabled', this.Disable, this); // can't use modelEvents.  It's used by the parent
	},
	id: 'TAG_DETAILS',
	DoAction(e) {
		switch (e.currentTarget.id) {
		case 'refresh':
			const poller = this.model.getPoller();
			poller.set({ command: 'refresh' });
			break;
		}
	},
	onBeforeRender() {
		this.model.getDeviceLibrary().on('change:protocol', this.render);
	},
	onRender() {
		const protocol = this.model.getUp('protocol');
		if (DEF.devices.settings[protocol]) {
			const settings = new DEF.devices.settings[protocol]({
				model: this.model
			});
			this.showChildView('options', settings);
		}
		this.RevertJSON();
	},
	Disable() { // disable all the alarm states for disabled devices
		const enabled = this.model.get('enabled');
		if (!enabled)
			this.model.getTags().map(t => t.set('alarm_state', 'normal'));
		this.model.set('enabled', enabled);
	},
	Delete() {
		// eslint-disable-next-line no-restricted-globals
		if (confirm(`Are you sure you want to delete this library item?  ${this.model.getName()}`))
			// eslint-disable-next-line no-restricted-globals
			if (confirm(`${this.model.getName()}: Really?  This will destroy all the tags, too!`)) {
				const tags = APP.models.tags.where({ d_id: this.model.id });
				for (const t in tags) {
					const tag = tags[t];
					console.log('    ', tags[t].getName());
					tag.destroy();
				}

				this.model.destroy();
				APP.Tools.DeployTags();
				APP.Route(`#DT/${this.model.collection_name}`); // refresh doesn't exist.  and neither does the model, so route to the collection
			}
	}
});


DEF.devices.DT.small_device = Backbone.Marionette.View.extend({
	className: 'device block',
	id       : 'status',
	template : _.template("<%=prefix%><span class='value'>	<%=value%></span>"),
	primary  : false,
	GetPrimary() {
		if (this.primary)
			return this.primary;

		const symbol = this.model.getUp('primary_symbol');
		const model = APP.models.tags.findWhere({
			tag_name: `${this.model.getUp('prefix')}_${symbol}`
		});
		this.primary = model;
		return model;
	},
	GetArm() {
		const symbol = this.model.getUp('alarm_arm');
		const tl = APP.models.tag_library.findWhere({
			symbol,
			d_id: this.model.get('d_id')
		});
		if (tl) {
			const model = APP.models.tags.findWhere({
				tl_id: tl.get('_id'),
				d_id : this.model.get('d_id')
			});
			return model;
		}
		return false;
	},
	templateContext() {
		const rs = { value: '' };
		// if (this.$el.is(':visible'))
		if (this.model.get('online') && this.model.get('enabled')) {
			const model = this.GetPrimary();
			if (model)
				rs.value = model.getValue();
		}

		return rs;
	},
	modelEvents: {
		change: 'render'
	},
	events: {
		click: 'Click'
	},
	initialize() {
		const model = this.GetPrimary();
		if (model) {
			this.listenTo(model, 'change:value', this.render);
			this.listenTo(model, 'change:alarm_state', this.render);
		}
	},
	onDomRefresh () {
		//	console.log('render', this.model.getName());
		if (this.$el.is(':visible'))
			if (this.model.get('enabled')) {
				if (this.model.get('online')) {
					const model = this.GetPrimary();
					if (model)
						this.$el
							.removeClass('alarm warning normal stale off')
							.addClass(model.get('alarm_state'));

					this.$el.addClass('ONLINE').removeClass('OFFLINE');
				} else {
					this.$el.removeClass('alarm warning normal stale off');
					this.$el.addClass('OFFLINE').removeClass('ONLINE');
				}
			}
			else {
				this.$el.removeClass('alarm warning normal stale off OFFLINE ONLINE');
			}
	},
	Click() {
		if (APP.design_time)
			APP.Route(`#DT/devices/${this.model.id}`);
		else
			APP.Route(`#devices/${this.model.id}`);
	}
});


DEF.devices.page = Backbone.Marionette.View.extend({
	template: require('./templates/page.html'),
	templateContext() {
		const rs = this.model.all_fields();
		rs.model = this.model;
		return rs;
	},
	regions: {
		tags   : '#tags',
		summary: '#summary',
		events : '#event_log_box'
	},
	ui: {
		command: '.btn'
	},
	modelEvents: {
		change: 'render'
	},
	events: {
		'click @ui.command': 'Command'
	},

	onRender() {
		document.title = `Device: ${this.model.getName()}`;
		const d_id = this.model.id;
		const Summary = Backbone.Marionette.View.extend({
			tagName  : 'table',
			className: 'table-left table',
			template : require('./templates/page_summary.html')
		});
		this.showChildView('summary', new Summary({ model: this.model }));

		this.showChildView('tags', new DEF.database.DT.Table({
			collection     : APP.models.tags,
			collection_name: 'tags',
			childView      : DEF.database.DT.child,
			viewFilter (m) {
				return m.model.get('d_id') === d_id;
			}
		}));

		this.showChildView('events', new DEF.layout.LOG_BOX.Main({
			collection: APP.models.events,
			viewFilter(m) {
				if (m.model.get('tag_id')) {
					const tag = APP.models.tags.get(m.modelget('d_id'));
					if (tag)
						return tag.getUp('poller_id') === d_id;
				}
				if (m.model.get('device_id'))
					return m.model.get('device_id') === d_id;

				return false;
			}
		}));
	},
	Command(e) {
		switch (e.currentTarget.id) {
		case 'edit':
			APP.Route(`#DT/devices/${this.model.id}`);
			break;
		case 'exit':
			APP.Route('#');
			break;
		}
	}
});
