module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

//
//debugger
var range_low = range_low !== undefined  ? range_low :"";
var range_high = range_high !== undefined  ? range_high :"";
var warning_low = warning_low !== undefined  ? warning_low : "";
var warning_high = warning_high !== undefined  ? warning_high : "";
var alarm_low = alarm_low !== undefined  ? alarm_low : "";
var alarm_high = alarm_high !== undefined  ? alarm_high : "";
var unit = unit !== undefined  ? unit : "";
var decimals = decimals !== undefined  ? decimals : "";
var decimal_shift = decimal_shift !== undefined  ? decimal_shift : 0;
var arm_time = arm_time !== undefined ? arm_time : "";
var alarm_sound = alarm_sound !== undefined ? alarm_sound : false;
var alarm_notify = alarm_notify !== undefined ? alarm_notify : false;
var warning_notify = warning_notify !== undefined ? warning_notify : false;
var alarm_ticket = alarm_ticket !== undefined ? alarm_ticket : false;
var warning_ticket = warning_ticket !== undefined ? warning_ticket : false;
var absurd_low = absurd_low !== undefined ? absurd_low : "";
var absurd_high = absurd_high !== undefined ? absurd_high: "";
var expected_low = expected_low !== undefined ? expected_low : "";
var expected_high = expected_high !== undefined ? expected_high: "";

__p+='\n'+
((__t=(APP.UI.select_from_object('cast',{
	raw:"Raw",analog:"Fixed" // "progress bar?"
}, cast, "field")))==null?'':__t)+
'\n\n</legend>\n\n<table>\n	<tr>\n		<th>Pre-Process</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object('preprocess',{
						none:"none",invert:"Invert", pint:"Parse Unsigned Int",psignedint: "Parse Signed Int", pfloatAB:"Parse Float (ABCD)", pfloatBA:"Parse Float (CDAB)",plongAB:"Parse Long (ABCD)", plongBA:"Parse Long (CDAB)",CtoF:"Celcius to Fahrenheit",FtoC:"Fahrenheit to Celcius","nounit":"Remove Unit"
					}, preprocess, "field")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Unit</th>\n		<td>\n			<input id=\'unit\' type=\'text\' value=\''+
((__t=(unit))==null?'':__t)+
'\' class=\'field\' placeholder=\'unit\' list=\'unitlist\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Deadband</th>\n		<td>\n			<input id=\'deadband\' type=\'number\' value=\''+
((__t=(deadband))==null?'':__t)+
'\' class=\'field\' placeholder=\'deadband\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Decimals</th>\n		<td>\n			<input '+
((__t=(cast!=='analog'?'disabled':''))==null?'':__t)+
' id=\'decimals\' type=\'number\' value=\''+
((__t=(decimals))==null?'':__t)+
'\' class=\'field\'\n				placeholder=\'decimals\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Mulitplier</th>\n		<td>\n			<!-- <input id=\'decimal_shift\' type=\'number\' value=\''+
((__t=(decimal_shift))==null?'':__t)+
'\' class=\'field\' placeholder=\'decimal shift\'> -->\n			'+
((__t=(APP.UI.select_from_object('decimal_shift',{
						"-3":"&times;0.001","-2":"&times;0.01","-1":"&times;0.1","0":"none","1":"&times;10","2":"&times;100","3":"&times;1000"
					}, decimal_shift, "field")))==null?'':__t)+
' </td>\n	</tr>\n	<tr>\n		<th colspan=2>Range (min &hellip; max)</th>\n	</tr>\n	<tr>\n		<td>\n			<input type=\'number\' id=\'range_low\' class=\'field\' required value=\''+
((__t=(range_low))==null?'':__t)+
'\'>\n		</td>\n		<td>\n			<input type=\'number\' id=\'range_high\' class=\'field\' required value=\''+
((__t=(range_high))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th colspan=2>'+
((__t=(APP.Lang('Alarm')))==null?'':__t)+
' Levels</th>\n	</tr>\n	<tr>\n		<td>\n			<input type=\'number\' id=\'alarm_low\' class=\'field\' placeholder="'+
((__t=(APP.Lang('alarm')))==null?'':__t)+
' low level"\n				value=\''+
((__t=(alarm_low))==null?'':__t)+
'\'>\n		</td>\n		<td>\n			<input type=\'number\' id=\'alarm_high\' class=\'field\' placeholder="'+
((__t=(APP.Lang('alarm')))==null?'':__t)+
' high level"\n				value=\''+
((__t=(alarm_high))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th colspan=2>Warning Levels</th>\n	</tr>\n	<tr>\n		<td>\n			<input type=\'number\' id=\'warning_low\' class=\'field\' placeholder="warning low level"\n				value=\''+
((__t=(warning_low))==null?'':__t)+
'\'>\n		</td>\n		<td>\n			<input type=\'number\' id=\'warning_high\' class=\'field\' placeholder="warning high level"\n				value=\''+
((__t=(warning_high))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n</table>\n<fieldset>\n	<legend>Advanced</legend>\n	<table>\n		<tr>\n			<th colspan=2>Expected Levels</th>\n		</tr>\n		<tr>\n			<td>\n				<input type=\'number\' id=\'expected_low\' class=\'field\' placeholder="expected low level"\n					value=\''+
((__t=(expected_low))==null?'':__t)+
'\'>\n			</td>\n			<td>\n				<input type=\'number\' id=\'expected_high\' class=\'field\' placeholder="expected high level"\n					value=\''+
((__t=(expected_high))==null?'':__t)+
'\'>\n			</td>\n		</tr>\n		<tr>\n			<th colspan=2>Absurd Levels</th>\n		</tr>\n		<tr>\n			<td>\n				<input type=\'number\' id=\'absurd_low\' class=\'field\' placeholder="absurd low level"\n					value=\''+
((__t=(absurd_low))==null?'':__t)+
'\'>\n			</td>\n			<td>\n				<input type=\'number\' id=\'absurd_high\' class=\'field\' placeholder="absurd high level"\n					value=\''+
((__t=(absurd_high))==null?'':__t)+
'\'>\n			</td>\n		</tr>\n		<tr>\n			<th>\n				Arm time\n			</th>\n			<td>\n				<input type=\'number\' id=\'arm_time\' class=\'field\' value=\''+
((__t=(arm_time))==null?'':__t)+
'\'\n					placeholder=\'timeout after alarm condition\'>\n			</td>\n		</tr>\n		<tr>\n			<th>\n				Arm Symbol\n			</th>\n			<td>\n				'+
((__t=(APP.UI.select_from_collection("alarm_arm", APP.models.tag_library.where({dl_id:dl_id}), "symbol", "name", alarm_arm)))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<th>Browser Notify</th>\n			<td>\n				<input type=\'checkbox\' class=\'field refresh\' id=\'alarm_notify\' '+
((__t=(alarm_notify?"checked":""))==null?'':__t)+
'>\n				'+
((__t=(APP.Tools.icon('alarm','Notify if this tag goes to '+APP.Lang('alarm'))))==null?'':__t)+
'\n				<input type=\'checkbox\' class=\'field refresh\' id=\'warning_notify\' '+
((__t=(warning_notify?"checked":""))==null?'':__t)+
'>\n				'+
((__t=(APP.Tools.icon('warning','Notify if this tag goes to '+APP.Lang('warning'))))==null?'':__t)+
'\n			</td>\n		</tr>\n		';
 if (SETTINGS.emails && SETTINGS.emails.alarm_ticket) { 
__p+='\n		<tr>\n			<th>Create Ticket</th>\n			<td>\n				<input type=\'checkbox\' class=\'field refresh\' id=\'alarm_ticket\' '+
((__t=(alarm_ticket?"checked":""))==null?'':__t)+
'>\n				'+
((__t=(APP.Tools.icon('alarm','Notify if this tag goes to '+APP.Lang('alarm'))))==null?'':__t)+
'\n				<input type=\'checkbox\' class=\'field refresh\' id=\'warning_ticket\' '+
((__t=(warning_ticket?"checked":""))==null?'':__t)+
'>\n				'+
((__t=(APP.Tools.icon('warning','Notify if this tag goes to '+APP.Lang('warning'))))==null?'':__t)+
'\n			</td>\n		</tr>\n		';
 } 
__p+='\n		<tr>\n			<th>\n				Alarm Sound\n			</th>\n			<td>\n				<input type=\'checkbox\' class=\'field refresh\' id=\'alarm_sound\' '+
((__t=(alarm_sound))==null?'':__t)+
'\n					'+
((__t=(alarm_sound?"checked":""))==null?'':__t)+
'> Enable\n			</td>\n		</tr>\n		';
 if (alarm_sound) { 
__p+='\n		<tr>\n			<td colspan=2>\n				<audio controls style=\'width:100%\'>\n					<source src="'+
((__t=(require("../../../tag/alarm.mp3")))==null?'':__t)+
'" type="audio/mpeg"></audio>\n\n			</td>\n		</tr>\n		';
 } 
__p+='\n	</table>\n</fieldset>';
}
return __p;
};
