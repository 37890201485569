DEF.reports = {};

DEF.reports.Initialize = function init(callback, constraints) {
	const options = {
		model     : DEF.reports.Model,
		url       : `${SETTINGS.dburl}/reports`,
		comparator: 'name'
	};
	return APP.InitializeModels('reports', options, callback, constraints);
};

DEF.reports.Model = DEF.TG.Model.extend({
	idAttribute    : '_id',
	collection_name: 'reports',
	defaults       : {
		name      : 'Report 1',
		template  : '*** REPORT ***\n** @DATE **\n|!TIME|! Col1   |! Col2   |! Col3   |\n@LOOP MONTH WEEK AVG\n| @TIME | val1 | val2 | val3 |\n@ENDLOOP\n\n\nend\n',
		recipients: '',
		frequency : 'never',
		hours     : 0,
		save      : false,
		ranges    : {} // report figures the mongo ranges for later query
	},
	db_spreadsheet: ['name', 'frequency', 'recipients'],
	db_columns    : ['frequency', 'recipients'],
	db_filters    : ['frequency'],
	getName() {
		return this.get('name');
	},
	GenerateReport(template, preview = false, program_options = {}) {
		this._options = {
			UNITS   : true,
			PLAINVAL: false,
			NOTAGS  : false
		};
		this._program_options = program_options;
		this._looplines = []; // holds line values in tables, used for summing
		this._classlist = []; // holds left/right justification in table columns
		template = template || this.get('template');
		const lines = template.split('\n');
		this.ranges = {};

		this._mode = '';

		let out = '';
		// let CUSTOMCSS = false;
		// try {
		// 	CUSTOMCSS = fs.readFileSync(`../../../public${SETTINGS.reportcss_url}`);
		// } catch (e) {
		// 	console.log(e);
		// 	CUSTOMCSS = false;
		// }
		// console.log(`../../../public${SETTINGS.reportcss_url}`, CUSTOMCSS);
		// out += `${CUSTOMCSS} `;
		for (let l = 0; l < lines.length; l++)
			out += this.ParseLine(lines[l], preview);
		if (this._mode === 'table')
			out += '</table>'; // sometimes there is no next line after the end of a table

		if (preview)
			this.set({ ranges: this.ranges, updated: Date.now() }, { silent: true });


		//	console.log(out);
		return out;
	},
	ParseLine(line, preview) {
		let bits;
		const sum = 0;
		const orig = line;
		if (!line.includes('@VAR')) {
			line = line.replace(/^\*\*\*(.+)\*\*\*$/g, '\n<h1>$1</h1>'); // H1
			line = line.replace(/^\*\*(.+)\*\*$/g, '\n<h2>$1</h2>'); // H2
			line = line.replace(/^\*(.+)\*$/g, '\n<h3>$1</h3>'); // H3
			line = line.replace(/^\* (.+)$/g, '\n<li>$1</li>'); // li
			line = line.replace(/^\*\* (.+)$/g, '\n<li>&nbsp;&nbsp;&nbsp;&nbsp;$1</li>'); // li
			line = line.replace(/\*(.+?)\*/g, '<b>$1</b>'); // bold
			line = line.replace(/~~(.+?)~~/g, '<strike>$1</strike>'); // italics
			line = line.replace(/~(.+?)~/g, '<i>$1</i>'); // italics
			line = line.replace(/^\* (.+)$/g, '\n<li>$1</li>'); // li
			line = line.replace(/\^(.)/g, '<sup>$1</sup>'); // ^1 superscript
		}

		const plain = line === orig;
		// console.log('   ', line);
		// if (line.substring(0, 3) === '---') // HR
		//	return '<hr>';

		let out = line;
		switch (line[0]) {
		case '|': // table
			if (line.substr(0, 4) === '|---') {
				out = '<tr><td class="hr" colspan=50><hr></td></tr>';
				break;
			}
			let left = ''; // hold 'left' or 'right'
			let td = 'td';
			if (this._mode !== 'table')
				this._classlist = [];

			bits = line.substring(1, line.length - 1).split('|');
			line = '<tr>';

			for (const b in bits) {
				let bit = bits[b].trim();
				td = 'td';
				left = this._classlist[b] || '';
				if (bit[0] === '!') { // check for header row
					td = 'th';
					bit = bit.substring(1, bit.length);
				}
				if (bit[0] === '>') {
					left = 'right';
					bit = bit.substring(1, bit.length);
				}
				if (this._mode !== 'table')
					this._classlist[b] = left;
				line += `<${td} class="${left}">${bit}</${td}>`;
			}
			line += '</tr>';


			// line = line.substring(1, line.length - 1);
			// line = `\n<tr>\n  <td>${line.replace(/\|/g, '</td>\n  <td>')}</td>\n</tr>`;
			// line = line.replace(/<td>!(.*?)<\/td>/g, '<th>$1</th>'); // headers
			// line = line.replace(/<td>>(.*?)<\/td>/g, '<td class="right">$1</td>'); // right
			// line = line.replace(/<th>>(.*?)<\/th>/g, '<th class="right">$1</th>'); // right
			if (!this._loop) {
				if (!this._looplines)
					this._looplines = [];
				// console.log(line, this.ParseValues(line, preview));
				line = this.ParseValues(line, preview);
				this._looplines.push(this._linevaluecache);
			}

			if (this._mode !== 'table') {
				this._mode = 'table';
				out = `\n\n<table>${line}`;
			} else {
				// table has ended
				out = line;
			}
			break;
		case '/': // comment
			return '';
		case '@': // macro
			bits = line.substring(1).split(' ');
			switch (bits[0]) {
			// case 'TOTAL':
			// 	var fn = bits[1];
			// 	console.log(fn);
			// 	break;
			case 'OPT':
				let new_val = bits[2];
				if (new_val) {
					switch (new_val.toLowerCase()) {
					case 'false':
						new_val = false; break;
					case 'true':
						new_val = true; break;
					}
					this._options[bits[1]] = new_val;
					out = '';
				}
				break;
			case 'VAR': // @VAR SUM #G1_KW+#G2_KW
				const last_unit = this._options.UNITS;
				this._options.UNITS = false;
				const formula = APP.Tools.strip_html_tags(this.ParseValues(bits[2]));
				if (!this._variables)
					this._variables = {};
				try {
					this._variables[bits[1]] = eval(formula);
					// console.log('formula', formula, bits[0], this._variables[bits[1]]);
				} catch (e) {
					console.warn('Bad forrmula', formula);
				}
				this._options.UNITS = last_unit;
				out = '';
				break;
			case 'WIDGET':
				out = this.DrawWidget(bits);
				break;
			case 'LOOP':
				this._loop = {
					cmd  : bits,
					lines: []
				};
				// console.log('bits', bits);
				out = '';
				break;
			case 'ENDLOOP':
				const parms = this.ParseLoopParms(this._loop.cmd);
				// console.log('parms', parms);
				out = '';
				if (parms) {
					this._looplines = [];
					for (let t = parms.from; t < parms.to; t += parms.step)
					// console.log('Loop start', APP.Format.datetime(parms.from));
						for (const l in this._loop.lines) {
							// console.log('>>', this._loop.lines[l]);
							const line2 = this.ParseValues(this._loop.lines[l], preview, t, parms.fn, parms.step);
							this._looplines.push(this._linevaluecache);
							out += line2;
						}
				}
				this._loop = false;
				break;
			case 'TOTAL':
				if (this._looplines) {
					out = '';
					const sums = [];
					for (const x in this._looplines)
						for (const y in this._looplines[x]) {
							if (!sums[y])
								sums[y] = 0;
							const number = Number(this._looplines[x][y]);
							switch (bits[1]) {
							case 'AVG':
								sums[y] += number / (this._looplines.length);
								break;
							case 'MAX':
								if (number > sum[y])
									sum[y] = number;
								break;
							case 'SUM':
							default:
								sums[y] += number;
							}
						}
					if (sums.length) {
						out += `\n<tfoot>\n<tr>\n  <th class="${this._classlist[0]}">${bits[1]}</th>`;
						for (const c in sums)
							out += `\n<th class="${this._classlist[(c | 0) + 1]}">${sums[c].toLocaleString()}</th>`;
						out += '</tr>\n</tfoot>';
						// console.log(out);
					}
				}
				break;
			default:
				out = this.ParseValues(line, preview);
				break;
			}
			break;
		default:
			out = this.ParseValues(line, preview);

			if (this._mode === 'table') {
				out = `\n</table>\n\n${line}`;
				this._mode = false;
			} else if (plain) {
				out += '<br>';
			}
		}
		out = out.replace(/---/g, '<hr />'); // replace --- with <hr>
		if (this._loop) {
			if (out !== '')
				this._loop.lines.push(out);
			return '';
		}

		return out;
	},
	DrawWidget(bits) {
		let out = '';
		const type = bits[1];
		const tags = bits[2];
		switch (type.toUpperCase()) {
		case 'CHART':
			out = this.DrawChart(tags);
			break;
		case 'GAUGE':
			out = this.DrawGauge(tags);
			break;
		}
		return out;
	},
	ParseValues(line, preview, time, fn, step) {
		let val = '';
		const valuecache = []; // store an array of all the values in the line, for totalizers!
		const tags = line.match(/#([\w_[\].:]+)/g);
		// const that = this;
		if (_.isArray(tags))
			for (let t = 0; t < tags.length; t++) {
				const match = tags[t];
				const bits = match.substring(1).match(/([\w/_.]+)/g);
				const tagname = bits[0];
				val = tagname;
				if (this._variables && typeof this._variables[tagname] !== 'undefined') {
					val = `<span class='value'>${this._variables[tagname]}</span>`;
				} else {
					const tag = APP.models.tags.findWhere({
						tag_name: tagname
					});
					if (tag) {
						if (preview || !fn)
							this.SetTagRanges(tag, time, fn, step);
						val = this.GetValue(tag, preview, time, fn, step, bits[1], bits[2]);
					}
					if (typeof val === 'string')
						valuecache.push(parseFloat(val.replace(/(<([^>]+)>)/ig, '')));
					else
						valuecache.push(val);
				}
				if (this._options.PLAINVAL)
					val = APP.Tools.strip_html_tags(val);
				line = line.replace(`${match}`, val);
			}

		const vars = line.match(/@([\w]+)/g);
		if (_.isArray(vars))
			for (const v in vars) {
				const key = vars[v].substring(1);
				if (!time)
					time = Date.now() - this.GetGoBack();
				// console.log('TIME', APP.Format.datetime(time), this.GetGoBack());
				switch (key) {
				case 'MAX':
					val = 'farts';
					break;
				case 'TIME':
					val = APP.Format.time(time);
					break;
				case 'DAY':
					const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
					val = days[time.getDay()];
					break;
				case 'DATE':
					val = APP.Format.date(time);
					break;
				case 'YEAR':
					val = new Date(time).getFullYear();
					break;
				case 'MONTH':
					val = new Date(time).getMonth();
					val = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][val];
					break;
				case 'DATETIME':
					val = APP.Format.datetime(time);
					break;
				}
				line = line.replace(`@${key}`, val);
			}
		if (this._options.NOTAGS)
			line = APP.Tools.strip_html_tags(line);
		this._linevaluecache = valuecache;
		if (time)
			line = `\n\n<!--LINE: ${new Date(time)} ${fn} ${step} -->${line}`;
		return line;
	},
	ParseLoopParms(parms) {
		const out = {};
		const [cmd, range, step, agg] = parms;
		if (range && step && agg) {
			switch (step) {
			case 'HOUR':
				out.step = 3600 * 1000;
				break;
			case 'DAY':
				out.step = 86400 * 1000;
				break;
			case 'WEEK':
				out.step = 86400 * 1000 * 7;
				break;
			case 'MONTH':
				out.step = 86400 * 1000 * 30;
				break;
			}
			let date = new Date();
			const goback = this.GetGoBack();

			if (goback)
				date = new Date(date - goback);
			// const now = date.getTime();

			let todaystart = new Date();
			if (goback)
				todaystart = new Date(todaystart - goback);
			todaystart.setHours(0, 0, 0, 0);

			let todayend = new Date();
			if (goback)
				todayend = new Date(todayend - goback);
			todayend.setHours(23, 59, 59, 999);
			switch (range) {
			case 'DAY':
				out.from = todaystart.getTime();
				out.to = todayend.getTime();
				break;
			case 'WEEK':
				out.from = todaystart - (86400 * 1000 * 7);
				out.to = todayend.getTime();
				break;
			case 'MONTH':
				out.from = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
				out.to = new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime();
				break;
			case 'YEAR':
				out.from = new Date(date.getFullYear(), 0, 1).getTime();
				out.to = new Date(date.getFullYear(), 12, 1).getTime();
				break;
			}
			out.fn = agg.toLowerCase();

			if (out.step && out.from)
				return out;
		}
		return false;
	},
	SetTagRanges(tag, time, fn, step) {
		if (tag) {
			// const goback = 0;

			const tag_name = tag.getName();
			// if (time)
			// 	console.log(tag_name, time, fn, step);
			if (!this.ranges[tag_name]) {
				this.ranges[tag_name] = {
					t: tag.id
				};
				if (time)
					this.ranges[tag_name].d = {
						lt : time,
						gte: time
					};
			}
			if (time) {
				if (!this.ranges[tag_name].d)
					this.ranges[tag_name].d = {
						lt : time,
						gte: time
					};
				this.ranges[tag_name].d.lt = Math.max(this.ranges[tag_name].d.lt, time + step);
				this.ranges[tag_name].d.gte = Math.min(this.ranges[tag_name].d.gte, time);
				// this.ranges[tag_name].d.gteh = APP.Format.datetime(this.ranges[tag_name].d.gte);
				// this.ranges[tag_name].d.lth = APP.Format.datetime(this.ranges[tag_name].d.lt);
			}
		}
	},

	/**
	 * If the generate_report script wants the date range to change via the -g opt
	 */
	GetGoBack() {
		let goback = 0;
		if (this._program_options && this._program_options.goback)
			switch (this._program_options.frequency) {
			case 'yearly':
				goback = 1000 * 60 * 60 * 24 * 365 * this._program_options.goback;
				break;
			case 'monthly':
				goback = 1000 * 60 * 60 * 24 * 30 * this._program_options.goback;
				break;
			case 'weekly':
				goback = 1000 * 60 * 60 * 24 * 7 * this._program_options.goback;
				break;
			case 'hourly': // this report runs at a specific hour on a day
			case 'daily':
				goback = 1000 * 60 * 60 * 24 * this._program_options.goback;
				break;
			}
		// if (goback)
		// 	console.log('goback', goback);
		return goback;
	},
	GetValue(tag, preview, time, fn, step, command, argument) {
		// if (tag.getName()!=="UE_KW") return;

		let val = 0;
		let min = Number.MAX_SAFE_INTEGER;
		let max = -Number.MAX_SAFE_INTEGER;
		let field = 'value';
		if (command === 'field')
			field = argument;
		if (tag.isNumber() && command !== 'field')
			val = tag.getValue(tag.getUp(field), true);
		else
			val = tag.get(field);


		// console.log(field, command, val, tag.getName(), tag.getDevice().getName());
		// console.log(tag.getName(), val, tag.get(field), field);
		// console.log(tag.getName(), APP.Format.datetime(time), APP.Format.datetime(time + step));
		if (time && !preview) {
			// const goback = this.GetGoBack();
			// const goback = 0;
			const data = APP.models.data_archive.filter((m) => {
				const d = m.get('d');
				const t = m.get('t');
				return t === tag.id && d >= time && d < time + step;
			});
			// console.log('data', fn, APP.models.data_archive.length, data.length);
			val = 0;
			if (data.length) {
				// console.log(tag.getName(), data.length, fn);
				let sum = 0;
				let count = 0;
				let up = 0;
				for (const d in data) {
					const datum = data[d];
					const value = datum.get('v');
					// console.log(tag.getName(), '>', value);
					sum += value;
					count++;
					if (value)
						up++;
					min = Math.min(min, value);
					max = Math.max(max, value);
				}
				switch (fn) {
				case 'sum':
					val = sum;
					break;
				case 'min':
					val = min;
					break;
				case 'max':
					val = max;
					break;
				case 'uptime':
					val = up / count;
					break;
				case 'avg':
					val = sum / count;
						break;
				case 'raw':
					break;
				default:
					console.warn('no function', fn, tag.getName());
				}
			}
		}

		let unit = tag.getUp('unit') || '';
		if (command === 'field' && field !== 'value')
			unit = '';
		if (command === 'unit') {
			const default_unit = APP.Unit.dehumanize(unit);
			const user_unit = APP.Unit.dehumanize(argument);
			if (default_unit !== user_unit && APP.Unit[default_unit] && APP.Unit[default_unit][user_unit]) {
				val = tag.getValue(APP.Unit[default_unit][user_unit](val), true);
				unit = user_unit;
			}
		}
		switch (field) {
		case 'on_time':
		case 'off_time':
			val = val.toFixed(1); // this is a stupid special case
		}

		let out;
		if (fn === 'uptime') {
			val = (val * 100).toLocaleString();
			out = `<span class="value">${val}</span>`;
		} else if (unit === '$') {
			// console.log(tag.getName(), val, (val).toLocaleString('us-US', { style: 'currency', currency: 'USD' }));
			val = Number(val).toLocaleString('us-US', { style: 'currency', currency: 'USD' });
			out = `<span class="value">${val}</span>`;
		} else {
			out = `<span class="value">${val}${this._options.UNITS ? `<span class='unit'>${unit}</span>` : ''}</span>`;
		}
		return out;
	},

	DrawGauge(arg1) {
		let out = '<!-- Gauge -->';
		const taglist = arg1.split(/\W+/);

		out += '<div class="gaugebox">';
		for (const t in taglist) {
			const tag = APP.models.tags.findWhere({
				tag_name: taglist[t]
			});
			if (tag) {
				const percent = APP.Format.clamp(tag.getPercent(), 0, 1) * 180;

				out += '<figure>\n';
				out += "<div class='gauge'>\n";
				out += `<div class='meter'  style='transform:rotate(${percent}deg);'></div>\n`;
				out += '</div>';
				out += `<figcaption>${tag.getName()} - ${tag.getValue()}</figcaption>`;
				out += '</figure>';
			}
		}
		out += '</div>';
		return out;
	},
	DrawChart(arg1) {
		let out = '';
		const taglist = arg1.split(/\W+/);

		out = "<table class='chart'>";

		for (const t in taglist) {
			const tag = APP.models.tags.findWhere({
				tag_name: taglist[t]
			});
			if (tag) {
				this.SetTagRanges(tag);
				const percent = APP.Format.clamp(tag.getPercent(), 0, 1) * 100;

				out += `\n<tr><td class='name'>${tag.getName()}</td>\n<td class='value'>${tag.getValue()}</td>`;
				out += `<td class='barbox'><div class='bar' style='width:${percent}%'></div></td>`;
			}
		}
		out += '</table>';


		return out;
	}
});
