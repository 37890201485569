APP.Tools = {

	/**
   * Turns "x.y.z = 5" into "x = {y: {z:5}}"
   * @param  {[type]} id  [description]
   * @param  {[type]} val [description]
   * @return {[type]}     [description]
   */
	explode_field_id(id, val) {
		if (id.indexOf('.') < 0)
			return [id, val];
		const value = {};
		const fields = id.split('.');
		const field = fields.shift();
		const field2 = fields.join('.');
		if (field2.indexOf('.') > 0) {
			const [A, B] = this.explode_field_id(field2, val);
			value[A] = B; // set the field
			return [field, value];
		}
		value[field2] = val;
		return [field, value];
	},
	deep_extend(destination, source) {
		for (const property in source)
			if (
				source[property] && source[property].constructor && source[property].constructor === Object
			) {
				destination[property] = destination[property] || {};
				this.deep_extend(destination[property], source[property]);
			} else {
				destination[property] = source[property];
			}

		return destination;
	},

	/**
   * returns a string with all the HTML tags stripped out:  m<span class='value'>open</span> -> open
   * @param {string} string some HTML
   */
	strip_html_tags(string) {
		return string.replace(/<\/?[^>]+(>|$)/g, '');
	},

	/**
   * Grab a value from the "settings" table
   * @param  {string} key Setting key
   * @return string       the value
   */
	setting(key) {
		const setting = APP.models.settings.get(key);
		if (setting)
			return setting.get('value');
		return '';
	},

	/**
   * Store values somewhere.  Currently LS, but should be user-record.
   * @param  {[type]} key [description]
   * @param  {[type]} val [description]
   * @return {[type]}     [description]
   */
	store(group, key, val) {
		key = `tg_${group}_${key}`; // prefix for uniqueness.
		if (val !== undefined) {
			if (_.isArray(val))
				val = `ARRAY*${val.join('|')}`;
			if (val === false)
				localStorage.removeItem(key);
			else
				localStorage.setItem(key, val);
		} else {
			val = localStorage.getItem(key);
			if (val && val.substring(0, 6) === 'ARRAY*')
				val = val.substring(6).split('|');
		}
		return val;
	},

	/**
	 * Returns true if number is, in fact, a numbery number
	 */
	is_numeric(number) {
		return !isNaN(parseFloat(number)) && isFinite(number);
	},

	/**
	 * Returns a HTML-formatted Font Awesome icon thing.
	 * @param {String} icon Name of font awesome icon, or one of the presets
	 * @param {String} title Toooltip title
	 * @param {String} classs Extra classname, optional
	 */
	icon(icon, title = icon, classs = '') {
		const id = icon;
		const lookup = {
			expand  : 'minus-square',
			collapse: 'plus-square',
			exit    : 'arrow-left',

			save  : 'save',
			revert: 'undo',
			cancel: 'ban',

			normal     : 'check',
			alarm      : 'circle-exclamation',
			warning    : 'triangle-exclamation',
			alarm_ack  : 'circle-exclamation',
			warning_ack: 'triangle-exclamation',
			info       : 'info-circle',
			stale      : 'chain-broken',
			off        : 'minus-circle',
			on         : 'plus-circle',
			ack        : 'bell-slash',


			add   : 'plus',
			edit  : 'pencil',
			undo  : 'undo',
			delete: 'remove',

			link: 'arrow-circle-right',

			rising : 'arrow-circle-up',
			falling: 'arrow-circle-down',

			settings      : 'bars',
			help          : 'question-circle',
			device_library: 'gears',
			devices       : 'gear',
			screens       : 'desktop',
			pollers       : 'binoculars', // "rss", //"terminal"//"tachometer",
			widgets       : 'bar-chart',
			tags          : 'tag',
			tag_library   : 'tags',
			users         : 'user',
			data          : 'hashtag',
			data_archive  : 'file-zipper',
			reports       : 'file-lines',
			files         : 'file',
			network       : 'sitemap',
			events        : 'calendar',
			events_archive: 'calendar',

			dataexport: 'download',
			perms     : 'check-square',
			editor    : 'desktop',
			notify: 'phone',
			
			phone: 'phone-volume',
			sms: 'comment-sms',

			home      : 'home',
			standard  : '',
			master    : 'television',
			mobilehome: 'mobile',

			formula: 'equals',

			number   : 'area-chart',
			boolean  : 'circle',
			date     : 'calendar',
			string   : 'align-left',
			totalizer: 'plus-circle',
			code     : 'list',
			rate     : 'caret-up'
		};
		if (!icon || typeof icon !== 'string')
			return '';
		if (icon.substring(0, 4) === 'http')
			return `<img class='icon' src='${icon}'>`;

		let icon2 = false;
		switch (icon) {
		case 'working':
		case 'loading':
			return "<span class='loading'><i class='fa fa-refresh fa-spin'></i></span>";
		default:
			if (!APP.widget_icons)
				APP.widget_icons = {};
			icon2 = lookup[icon] || APP.widget_icons[icon] || icon;
		}
		if (icon2)
			return `<i id='icon_${id}' title='${title}' class='icon fa-solid fa-${icon2} ${classs}'></i>`;
		return false;
	},
	check_collision(boxa, boxb) {
		return !(boxb.x + 1 > boxa.x + boxa.w || boxb.x + boxb.w - 1 < boxa.x || boxb.y + 1 > boxa.y + boxa.h || boxb.y + boxb.h - 1 < boxa.y);
	},

	/**
	 * Every so often, some records can't find their related records, due to stupid crap like deleting the tag_library.  Whenever this
	 * happens, it increments a "ORPHAN" counter.  So, run this thing to kill all records that have, for example, an  ORPHAN count greater than 10
	 * @param {int} threshold
	 */
	KillOrphans(killem, threshold = 10) {
		// const killem = true;
		const cols = ['tag_library', 'tags', 'devices', 'widgets', 'data'];
		for (const c in cols) {
			const collection_name = cols[c];
			const waifs = APP.models[collection_name].filter(m => m.get('ORPHAN') > threshold);
			console.log(collection_name, waifs);
			for (const w in waifs) {
				console.log(`${(killem ? 'killing ' : 'can kill') + collection_name}:${waifs[w].getName()}`);
				if (killem)
					APP.models[collection_name].remove(waifs[w]);
			}
		}
		if (!killem)
			console.info('Pass true to kill them all');
	},

	/**
	 * Cleans out a lot of the stuff that has built up in localStorage over time.
	 * @param {bool} full Get rid of it all
	 */
	CleanLocalStorage(full) {
		let match;
		const partials = ['tg_chooser', 'tg_dt', 'tg_expand', 'tg_filter', 'tg_filtersearch', 'tg_prop', 'tg_export', 'tg_alarm', 'backfill'];
		// const keepers = ['tg_auth_id'];
		const keys = Object.keys(localStorage);
		console.log('LS Key length', keys.length);
		for (const k in keys) {
			const key = keys[k];
			match = false;
			if (!full)
				for (const p in partials) {
					const partial = partials[p];
					match = match || (key.indexOf(partial) === 0);
				}

			if (match || full) {
				localStorage.removeItem(key);
				console.log(key);
			}
		}
		console.log('LS Key length', Object.keys(localStorage).length);
	},
	SendMail(to, subject, body, callback) {
		const request = require('request');
		const rs = false;
		const uri = SETTINGS.posturl;
		// const target = `${uri}/sendmail/${to}/${subject}/${body}`;
		const target = `${uri}/sendmail`;
		// const options = {
		// 	url: target,
		// };
		console.log('==>', target, callback);
		// request.post(target).form({
		// 	to, subject, body,
		// });

		request.post(target, { form: { to, subject, body } }, callback);
		return rs;
	},
	Notify(state, message, callback, uid = false) {
		const request = require('request');
		console.log(state, message);
		let rs = false;
		if (!uid)
			uid = APP.USER.id;
		const user = APP.models.users.get(uid);
		if (user) {
			const uri = SETTINGS.posturl;
			let phone = user.get('phone');
			console.log('notify: ', user.getName(), phone);
			if (typeof phone !== 'number')
				phone = parseInt(phone.replace(/[^0-9]/g, ''), 10);
			const target = `${uri}/notify/${phone * phone}/${state}/${message}`;
			const options = {
				url    : target,
				headers: {
					'Access-Control-Allow-Origin': '*',
					Vary                         : 'Origin',
					mode                         : 'no-cors'
				}
			};
			request(options, callback);

			// $.get(target, callback);
			user.set({
				notifications: APP.USER.get('notifications') + 1
			});
			rs = true;
		}
		return rs;
	},
	Call(state, message, callback, uid = false) {
		const request = require('request');
		console.log(state, message);
		let rs = false;
		if (!uid)
			uid = APP.USER.id;
		const user = APP.models.users.get(uid);
		if (user) {
			const uri = SETTINGS.posturl;
			let phone = user.get('phone');
			console.log('notify: ', user.getName(), phone);
			if (typeof phone !== 'number')
				phone = parseInt(phone.replace(/[^0-9]/g, ''), 10);
			const target = `${uri}/call/${phone * phone}/${state}/${message}`;
			const options = {
				url    : target,
				headers: {
					'Access-Control-Allow-Origin': '*',
					Vary                         : 'Origin',
					mode                         : 'no-cors'
				}
			};
			request(options, callback);

			// $.get(target, callback);
			user.set({
				notifications: APP.USER.get('notifications') + 1
			});
			rs = true;
		}
		return rs;
	},
	DeployTags() {
		console.log('* Deploy Tags');
		const tag_libs = [];
		const devs = [];

		const device_library = APP.models.device_library.where({});
		for (const dl in device_library)
			device_library[dl].PopulateDefaultTagLibrary();

		const devices = APP.models.devices.where({});
		for (const d in devices) {
			const d_id = devices[d].id;
			const dl_id = devices[d].get('dl_id');
			devs.push(d_id);

			const tag_library = APP.models.tag_library.where({ dl_id });

			// console.log(tag_library);
			for (const tl in tag_library) {
				// console.log(tag_library[tl]);
				const tl_id = tag_library[tl].id;
				tag_libs.push(tl_id);

				let tag = APP.models.tags.findWhere({
					dl_id,
					d_id,
					tl_id
				});
				const tag_name = `${devices[d].get('prefix')}_${tag_library[tl].get('symbol')}`;
				if (!tag) {
					tag = APP.models.tags.create({
						dl_id, d_id, tl_id, tag_name
					});
					console.log('created ', tag_name, tag.getName());
				} else if (tag.get('tag_name') !== tag_name) {
					if (tag.get('tag_name').indexOf('TAG1') === -1) {
						console.log(tag.get('tag_name'), '->', tag_name);
						const oldnames = tag.get('oldnames') || [];
						oldnames.push({ n: tag.get('tag_name'), id: tag.id, d: Date.now() });
						tag.set({ tag_name, oldnames });
					}
				}
			}
		}

		_.delay(() => {
			const tags = APP.models.tags.where({});
			for (const t in tags)
				if (
					tag_libs.indexOf(tags[t].get('tl_id')) === -1 || devs.indexOf(tags[t].get('d_id')) === -1
				) {
					console.log('del', tags[t].get('tag_name'));
					APP.models.tags.remove(tags[t]);
				}
		}, 1500);

		return false;
	},

	/**
   * Given #0FF, and 0.5, returns a color 50% lighter than the original
   * @param {string} color   Hex value of color, with leading #
   * @param {float} percent 0%=same color, -0.5 = 50% darker, 0.5 = 50% lighter
   */
	ShadeColor(color, percent) {
		// https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
		color = color.slice(1);
		if (color.length === 3)
			color = color
				.split('')
				.map(v => v + v)
				.join('');

		const f = parseInt(color, 16);
		const t = percent < 0 ? 0 : 255;
		const p = percent < 0 ? percent * -1 : percent;
		const R = f >> 16;
		const G = (f >> 8) & 0x00ff;
		const B = f & 0x0000ff;
		color = (0x1000000 + ((Math.round((t - R) * p) + R) * 0x10000) + ((Math.round((t - G) * p) + G) * 0x100) + (Math.round((t - B) * p) + B));
		return `#${color.toString(16).slice(1)}`;
	},
	GetFakeTag() {
		const tag = new DEF.tags.Model({
			tag_name    : 'FAKE_TAG',
			type        : 'number',
			range_low   : 0,
			range_high  : 100,
			polling_rate: 60,
			warning_low : 20,
			warning_high: 80,
			alarm_low   : 10,
			alarm_high  : 90,
			unit        : '',
			value       : Math.random() * 100
		});
		return tag;
	},

	/**
	 * A utility to figure out why there's so much data.
	 * @param {string} data dataa|data_archive
	 */
	CountData(data = 'data') {
		const out = {};
		APP.models[data].each((m) => {
			const tag = APP.models.tags.get(m.get('t'));
			if (tag) {
				const tag_id = tag.get('tag_name');
				out[tag_id] = out[tag_id] ? out[tag_id] + 1 : 1;
			}
		});
		return out;
	},

	/**
	 * Compute an average, ignoring zeros.  Use in a formula:  AVERAGE([#G1_KW,#G2_KW,...])
	 * @param {array} values bunch of values
	 */
	Average(values) {
		let count = 0;
		let sum = 0;
		for (const v of values)
			if (v) {
				count++;
				sum += v;
			}

		return sum / count;
	}

};

/** ***
 * Use this to convert standard terms to user-defined terms
 *
 * APP.Lang('alarm') ==> 'shutdown'
 */
APP.Lang = function lang(key) {
	let key2 = key.toLowerCase();
	if (key2.slice(-1) === 's')
		key2 = key2.slice(0, -1);
	let ack = '';
	if (key2.indexOf('_ack') > 1) {
		key2 = key2.replace('_ack', '');
		ack = '_ack';
	}

	let val = key2;
	const setting = APP.models.settings.get(key2);
	if (setting)
		val = setting.get('value');
	if (key === APP.Format.capitalize(key))
		val = APP.Format.capitalize(val);
	if (key.slice(-1) === 's')
		val += 's';
	val += ack;
	return val;
};

APP.Unit = {
	humanize  : unit => (unit ? unit.replace('_', '/').replace('3', '³').replace('2', '²') : unit),
	dehumanize: unit => (unit ? unit.replace('/', '_').replace('³', '3').replace('²', '2') : unit),
	GetUnit   : unit => APP.USER.get(APP.Unit.dehumanize(unit)) || unit,
	ºC        : {
		ºF: val => (val * 1.8) + 32,
		ºK: val => val + 273.15
	},
	ºF: {
		ºC: val => (val - 32) / 1.8,
		ºK: val => ((val + 459.67) * 5) / 9
	},
	kW: {
		HP    : val => val * 1.3596216173,
		BTU_hr: val => val * 3412.142
	},
	HP: {
		kW: val => val / 1.3596216173
	},
	kWh: {
		kJ   : val => val * 3600,
		BTU  : val => val * 3412.14163312794,
		MMBTU: val => val * 0.00341214163312794,
		CCF  : val => val * 100000 / 29.3072 // therm https://en.wikipedia.org/wiki/Therm
	},
	bar: {
		psi: val => val * 14.504,
		atm: val => val * 0.986923
	},
	psi: {
		bar: val => val / 14.504
	},
	scfm: {
		m3_h: val => val * 1.7
	},
	scfh: {
		scfm: val => val / 60,
		m3_h: val => val / 60 * 1.7
	},
	m3_h: {
		scfm: val => val / 1.7,
		scfh: val => val / 1.7 * 60
	},
	m_s: {
		mph: val => val * 2.23694
	},
	mph: {
		m_s: val => val / 2.23694
	},
	l: {
		gal: val => val * 0.264172
	},
	// m3: {
	// 	gal  : val => val * 264.172,
	// 	MMBTU: val => val * 0.03507,
	// },
	m3: {
		kWh  : val => val * 10.395, // https://www.uniongas.com/business/save-money-and-energy/analyze-your-energy/energy-insights-information/conversion-factors
		gal  : val => (val * 35.3146701117 * 960) / 83000,
		BTU  : val => val * 35.3146701117 * 960,
		ft3  : val => val * 35.3146701117,
		MMBTU: val => val * 35.3146701117 * 0.0012 // https://sciencing.com/convert-meters-natural-gas-mmbtus-5780192.html
	},
	gal: {
		m3: val => val / 21.8323967,
		l : val => val * 0.264172
	},
	MBTU_hr: {
		lb_hr: val => val * 1000 / 1194
	},
	lb_hr: {
		MBTU_hr: val => val / (1000 / 1194),
		kg_hr  : val => val * 0.453592
	},
	MWh: {
		Mj        : val => val * 3600, // https://www.convertunits.com/info/megawatt+hour
		gal_LPG   : val => val * 35.729230155989,
		gal_diesel: val => val * 24.57002457002457

	}

};

APP.Convert = {
	nounit(val) {
		if (typeof val === 'string')
			return parseFloat(val.replace(/\D/g, ''));
		return val;
	},
	CtoF(val) {
		return (val * (9 / 5)) + 32;
	},
	FtoC(val) {
		return (val - 32) * (5 / 9);
	},
	pint(val) {
		return parseInt(val, 10);
	},
	// Parse a signed int.
	// psignedint(val) {
	// 	if (val > 32767)
	// 		return val - 65536;
	// 	return parseInt(val, 10);
	// },
	psignedint(number) {
		// if the number is desired as a float from an int with a binary point
		// convert the unsigned int to a float with the binary point at the {fraction} bit

		// convert from unsigned int to signed
		const b = new ArrayBuffer(2);
		const u = new Uint16Array(b);
		const i = new Int16Array(b);

		u[0] = number;
		const signedNumber = i[0];

		return signedNumber;
	},
	// psignedint(val) {
	// 	const nbit = 10;
	// 	val <<= 32 - nbit;
	// 	val >>= 32 - nbit;
	// 	return val;
	// },
	pfloat(val) {
		return parseFloat(val);
	},
	invert(val) {
		if (typeof val === 'boolean' || val === 0 || val === 1)
			return !val;
		return -val;
	},
	bitmask(val, opt) {
		let bit = opt.bit || 0;
		bit -= 1;
		return !!(val & (1 << bit));
	},
	// Parse Float: AAAA BBBBB
	pfloatAB(val) {
		val = this._number_to_float(val[0], val[1]);
		return val;
	},
	// Parse Float: BBBB AAAAA (big endian)
	pfloatBA(val) {
		if (val instanceof Array) {
			val = this._number_to_float(val[1], val[0]);
			return val;
		}
		return false;
	},
	// Parse LONG int
	plongAB(val) {
		return this._number_to_long(val[0], val[1]);
		// return `${val[0]}${val[1]}`;
	},
	// Parse LONG int BBBB AAAA (big endian)
	plongBA(val) {
		return this._number_to_long(val[1], val[0]);
	},

	/**
	 *  Converts two 16 bit ints into one LONG.  Modbus.
	 * Several ways to fo it:  https://stackoverflow.com/questions/35517561/combine-and-convert-two-16-bit-numbers-into-one-32-bit-number-float-in-javascr
	 * @param {int} low
	 * @param {int} high
	 */
	_number_to_long(low, high) {
		return (high << 16) | low;
	},

	/**
	 *  Converts two 16 bit ints into one FLOAT.  Modbus.
	 * @param {int} low
	 * @param {int} high
	 */
	_number_to_float(low, high) {
		const fpnum = low | (high << 16);
		const negative = (fpnum >> 31) & 1;
		let exponent = (fpnum >> 23) & 0xFF;
		let mantissa = (fpnum & 0x7FFFFF);
		if (exponent === 255) {
			if (mantissa !== 0)
				return Number.NaN;
			return (negative) ? Number.NEGATIVE_INFINITY
				: Number.POSITIVE_INFINITY;
		}
		if (exponent === 0)
			exponent++;
		else
			mantissa |= 0x800000;
		exponent -= 127;
		let ret = (mantissa * 1.0 / 0x800000) * (2 ** exponent);
		if (negative)
			ret = -ret;
		return ret;
	}

};
APP.DateFns = require('date-fns');

APP.Format = {
	image(val, classs = '') {
		let out = '';
		if (val)
			if (val.indexOf('http') === 0) {
				out = `<img src='${val}'>`;
			} else if (val.indexOf('data:') === 0) {
				out = `<img src='${val}'>`;
			} else {
				out = APP.Tools.icon(val, val, classs);
			}

		return out;
	},
	raw(val) {
		return val;
	},
	standard(val) {
		return val;
	},
	caps(val) {
		return val.toUpperCase();
	},
	capitalize(str) {
		const splitStr = str.toLowerCase().split(' ');
		for (let i = 0; i < splitStr.length; i++)
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

		return splitStr.join(' ');
	},
	camel(str) {
		const splitStr = str.toLowerCase().split('_');
		for (let i = 0; i < splitStr.length; i++)
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

		return splitStr.join('');
	},
	analog(val, attr) {
		return this.fixed(val, attr.decimals);
	},

	/**
	 * Convert a code value to it's associated text, as defined by the "code" attribute
	 * @param {number} val the code
	 * @param {object} attr the library configuration.  code[123] = 'Alarm'
	 */
	code(val, attr) {
		return (attr.code && attr.code[val]) ? attr.code[val] : `Code ${val}`;
	},
	signed(val, attr) {
		//		console.log(val, attr);
		if (val > 32766)
			val -= 65536;
		return this.fixed(val, attr.decimals);
	},
	switch(val) {
		return val ? APP.Tools.icon('toggle-on', 'On') : APP.Tools.icon('toggle-off', 'Off');
	},
	alarmled(val, attr) {
		return `<div class='led_value ${attr.alarm_state.toUpperCase()}'></div>`;
	},
	led(val) {
		return `<div class='led_value ${val ? 'ON' : 'OFF'}'></div>`;
	},
	bool_label(val, attr) {
		return val ? attr.on_text : attr.off_text;
	},

	/**
	 * Return a string suitable for device prefixes
	 * TODO: prefer numbers and consonants
	 * @param  {[type]} val [description]
	 * @return {[type]}     [description]
	 */
	device_prefix(val) {
		const max = 5;
		val = val.toUpperCase();
		if (val.length > max)
			val = val[0] + val.substring(1).replace(/[saeiou.]/ig, '');

		return val.substring(0, 5);
	},

	/**
	 * REturns a string suitable for URL.
	 * @param  {[type]} val [description]
	 * @return {[type]}     [description]
	 */
	url(val) {
		return val.trim().replace(/[^A-Za-z0-9\- ]/g, '').replace(/\s{2,}/g, ' ').replace(/\s/g, '-')
			.toLowerCase();
	},

	/**
	 * Returns a float, truncated to the number of decimals
	 * @param  {float} val The original value
	 * @param  {int} dec Number of decimals
	 * @return {string}     "5.50"
	 */
	fixed(val, dec) {
		val = parseFloat(val);
		return val.toFixed(dec); // oddly, this returns a string.  But if the string was "56.0", then the number would be 56, without decimals.
	},
	float(val, dec) {
		return APP.Format.fixed(val, dec);
	},

	/**
	 * Returns a value not exceeding min or max.  clamp(15,0,10) = 10;
	 * @param  {float} val value
	 * @param  {float} min Min value
	 * @param  {float} max Max value
	 * @return {float}     Value, not exceeding Min or Max
	 */
	clamp(val, min, max) {
		return Math.max(Math.min(val, max), min);
	},

	/**
	 * Wraps the values arround.  wrap(15,0,10) = 5
	 * @param {float} val
	 * @param {float} min
	 * @param {float} max - max value.  NOT inclusive.  <10, not <=10
	 */
	wrap(val, min, max) {
		const size = max - min;
		if (val < min)
			return max + min - APP.Format.wrap(min + max - val - 1, min, max) - 1;
		return ((val - min) % size) + min;
	},

	/**
	 * Returns a number with commas inserted appropriately
	 * @param  {float} val A number
	 * @return {string}     A number, with , in the thousands
	 */
	number(val) {
		let var2 = '';
		if (val !== undefined)
			var2 = Number(val).toLocaleString();
		return var2;
	},
	bytes(val) {
		let unit = 'b';
		if (val > 1000) {
			unit = 'kb';
			val /= 1000;
		}
		if (val > 1000) {
			unit = 'mb';
			val /= 1000;
		}
		return APP.Format.fixed(val, 1) + unit;
	},

	/**
	 * Returns a number with commas and dollarsigns and all that shit removed.
	 * Deccimals remain, though
	 * @param  {string} val "$5,500.00"
	 * @return {number}     5500
	 */
	pure(val) {
		if (_.isString(val))
			val = val.match(/[.\d]+/g)
				.join([]);

		return Number(val);
	},

	/**
	 * Returns the value formatted as money
	 * @param  {float} val The money value
	 * @return {string}     $5.50
	 */
	money(val) {
		val = Number(val);
		let sign = 'zero';
		if (val < 0)
			sign = 'negative';
		if (val > 0)
			sign = 'positive';
		return `<span class="money ${sign}">$${val.toFixed(2)
			.replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</span>`;
	},

	/*
██████   █████  ████████ ███████ ███████
██   ██ ██   ██    ██    ██      ██
██   ██ ███████    ██    █████   ███████
██   ██ ██   ██    ██    ██           ██
██████  ██   ██    ██    ███████ ███████
*/

	/**
	 * These two "_fix" functions is because the times are either in seconds or miliseconds
	 * @param  {[type]} time [description]
	 * @return {[type]}      [description]
	 */
	_fixtime(time) {
		if (typeof time === 'string')
			time = parseInt(time, 10);
		if (time < 14700000000)
			time *= 1000;
		return time;
	},
	_fixdate(time) {
		return new Date(this._fixtime(time));
	},
	// returns dates in unix standard format
	sysdate(time = Date.now()) {
		const date = new Date(time);
		const datef = `${date.getFullYear()}-${(`00${date.getMonth() + 1}`)
			.slice(-2)}-${(`00${date.getDate()}`)
			.slice(-2)}`;
		return datef;
	},
	date(time) {
		if (!time)
			return '--';
		const date = this._fixdate(time);
		const datef = `${(`00${date.getMonth() + 1}`)
			.slice(-2)}/${(`00${date.getDate()}`)
			.slice(-2)}/${date.getFullYear()}`;
		//		return "<a href='/calendar/date/" + date.toISOString()
		//			.slice(0, 10) + "' class='route'>" + datef + "</a>";
		return datef;
	},
	time(time) {
		if (!time)
			return '--';
		const date = this._fixdate(time);
		const datef = `${date.getHours()}:${(`00${date.getMinutes()}`)
			.slice(-2)}:${(`00${date.getSeconds()}`)
			.slice(-2)}`;
		return datef;
	},
	datetime(time) {
		if (!time)
			return '--';
		return `${APP.Format.date(time)} ${APP.Format.time(time)}`;
	},
	// Returns m:h, or d/m m:h or d/m/y m:h
	simpletime(time) {
		let out;
		if (time < Date.now() - (60 * 60 * 24 * 1000)) {
			out = APP.Format.datetime(time);
			out = out.replace(' 0:00:00', '');
			out = out.replace(`/${new Date().getFullYear()}`, '');
		} else {
			out = APP.Format.time(time);
			if (out === '0:00:00')
				out = 'midnight';
			if (out === '12:00:00')
				out = 'noon';
		}
		return out;
	},
	// reutrns "a minute ago"
	relativetime(time) {
		if (!time)
			return 'never';
		return `<span title='${new Date(time)}'>${APP.DateFns.formatDistanceToNow(
			time
		)}</span>`
		// const moment = require('moment');
		// const out = moment(time).fromNow();
		// return `<span title='${new Date(time)}'>${out.replace('Invalid date', 'never')}</span>`;
		//
		//
		// var out;
		// if (time < Date.now() - 60 * 60 * 24 * 1000) {
		// 	out = APP.Format.datetime(time);
		// 	out = out.replace(" 0:00:00", "");
		// } else {
		// 	out = APP.Format.time(time);
		// 	if (out == "0:00:00")
		// 		out = "midnight";
		// 	if (out == "12:00:00")
		// 		out = "noon";
		// }
		// return out;
	},

	/**
	 * Return a human-friendly duration, such as "17 minutes"
	 * @param {seconds} time in seconds
	 */
	duration(time) {
		if (time > 0 && time < Date.now())
			return APP.DateFns.formatDistanceStrict(
				new Date() - time * 1000,
				new Date()
			)
		return 'unknown';
		// const moment = require('moment');
		// return moment.duration(time - 0, 's').humanize();
		// return APP.Format.livetime(Date.now() - time * 1000);
	},
	livetime(time) {
		// debugger
		if (time === false)
			return 'never';
		if (time < 100000)
			return 'forever';
		time = this._fixtime(time);
		const rel = APP.Format._livetime(time);
		const unit = rel.split(' ')[1] || 'sec';
		const html = `<span class='livedate nowrap ${unit}' title='${APP.Format.datetime(time)}' data-raw='${time}'>${rel}</span>`;
		return html;
	},
	// this livetime is called by  the setInterval, to update every so often.  Use the real livetime.
	_livetime(time) {
		let delta = Math.abs(Date.now() - time);
		delta /= 1000;
		const div = [60, 60, 24, 30, 90, 300];
		const units = ['sec', 'min', 'hours', 'days', 'months', 'years'];
		let out = '';
		for (const i in div) {
			if (delta < div[i] * 1.5) {
				out = `${APP.Format.fixed(delta, i)} ${units[i]}`;
				break;
			}
			delta /= div[i];
		}
		if (out === '0 sec')
			out = 'now';
		if (out === '')
			out = APP.Format.datetime(time);
		return out;
	},
	monday(date, sysdate) {
		if (!date)
			date = new Date();
		if (_.isString(date))
			date = new Date(date);
		if (!_.isDate(date))
			date = new Date(date);
		const day = date.getDay() || 7;
		if (day !== 1)
			date.setHours(-24 * (day - 1));
		if (sysdate)
			return APP.Format.sysdate(date);
		return date;
	},

	/**
	 * Returns html, given markdown
	 * @param  {text} 'marked' Markdown formatted text
	 * @return {html}          HTML formatted text
	 */
	// markdown: require('marked'),
	/**
	 * cleans up html
	 * @param {htmlstring} str
	 */
	// htmlentities(str) {
	// 	return String(str)
	// 		.replace(/&/g, '&amp;')
	// 		.replace(/</g, '&lt;')
	// 		.replace(/>/g, '&gt;')
	// 		.replace(/"/g, '&quot;');
	// },

	/**
	 * Parses "bla.. #1.5...bla" into "bla.. <a href=task..> ..bla"
	 * @param  {String} str The raw text
	 * @return {String}     The text with HTML links for things that match
	 */
	linktext(str) {
		// TODO: pick up emails and urls
		if (APP.models.tasks)
			return str.replace(/(#)(\d.[\d.]+)/, APP.Format._replace);
		return str;
	},
	_replace(match, key, id) {
		return APP.GetLink('tasks', id, id);
	},

	/**
	 * Capitalize first letters
	 * @param  {string} text "a things"
	 * @return {string}      "A thing"
	 */
	first(text) {
		if (text.charAt) // sometimes a number sneaks in here.
			return text.charAt(0).toUpperCase() + text.slice(1);

		return text;
	}
};

APP.UI = {
	radio_from_object(id, list, defaultt) {
		let out = '';

		for (const key in list) {
			out += `<input class='field' type="radio" id="${id}" name="${id}" value="${key}" ${key === defaultt ? 'checked' : ''}>`;
			out += `<label for="${id}">${list[key]}</label>`;
		}

		return out;
	},

	/**
	 * Create a HTML <select> list from an array of items
	 * @param {string} id name of field
	 * @param {array|object} list A list of things, in array or object form
	 * @param {string} defaultt Default setting
	 * @param {string} classs [optional] CSS classs
	 * @param {object} options [optional] extra options
	 */
	select_from_object(id, list, defaultt, classs = 'field', options = {}) {
		let out = `<select id='${id}' class='${classs} default'>`;
		// if (Array.isArray(list))
		// 	list = { ...list };
		let has_key = true;
		if (_.isArray(list)) {
			has_key = false;
			list = _.uniq(list);
			if (list.indexOf(defaultt) !== -1)
				defaultt = list.indexOf(defaultt);
		}

		if (options.blank)
			out += "<option value=''></option>";
		for (const key in list)
			if (_.isObject(list[key])) {
				out += `<optgroup label='${key}'>`;
				for (const key2 in list[key]) {
					out += `<option value='${list[key][key2]}'`;
					if (list[key][key2] === `${defaultt}`) // == because "1" == 1
						out += ' selected ';
					out += `>${APP.Format.first(list[key][key2])}</option>`;
				}
				out += '</optgroup>';
			} else {
				out += `<option value='${has_key ? key : list[key]}'`;
				if (key === `${defaultt}`)
					out += ' selected ';
				if (list[key] && list[key].replace)
					list[key] = list[key].replace('_', ' ');
				out += `>${APP.Format.first(list[key])}</option>`;
			}

		out += '<select>';
		return out;
	},
	select_from_collection(id, list, key, val, defaultt, classs = 'field') {
		let out = `<select id='${id}' class='${classs} default'>`;
		out += "<option value=''></option>";
		if (_.isString(list))
			list = APP.models[list];
		if (!_.isArray(list))
			list = list.models;
		for (let l = 0; l < list.length; l++) {
			const model = list[l];
			out += `<option value='${model.get(key)}'`;
			if (model.get(key) === defaultt)
				out += ' selected ';
			out += `>${model.getUp(val)}</option>`;
		}
		out += '<select>';
		return out;
	},
	picklabel(id, val) {
		return APP.UI.select_from_object(id, ['none', 'tag_name', 'name', 'prefix', 'symbol'], val);
	},

	/**
	 * Draw a generic INPUT fuelds
	 * @param {*} id
	 * @param {*} val
	 * @param {*} type
	 * @param {*} placeholder
	 * @param {*} tooltip
	 * @param {*} classs
	 */
	input(id, val, type = 'text', placeholder = '', tooltip = '', classs = 'field') {
		return `<input placeholder='${placeholder}' type='${type}' id='${id}' value='${val}' title='${tooltip}' class='${classs}'>`;
	},
	slider(id, val, min, max, step = 0.1, classs = 'field live') {
		return `<input class='${classs} default' step='${step}' type='range' min='${min}' max='${max}' id='${id}' value='${val}'>`;
	},
	checkbox(id, val, label, tooltip = '', classs = 'field') {
		return `<label title='${tooltip}'><input type='checkbox' id='${id}' value='true' ${val ? 'checked' : ''} class='${classs} live default'> ${label || id}</label>`;
	},

	tabs(id, val, divs, label, classs = 'field') {
		let html = `<div class='tabs ${id}' id='tabs'>`;
		html += `<input type='hidden' class='${classs} default position' id='${id}' value='${val}'>`;
		html += "<div id='tabbox'>";
		if (label)
			html += `<div class='tab label'>${label}</div>`;
		for (const d in divs)
			html += `<div class='tab' id='${d}'>${divs[d]}</div>`;


		html += '</div>';
		html += '<div id="body"></div>';

		html += '</div>';
		_.defer(APP.UI._tabs_init);
		_.delay(APP.UI._tabs_settab.bind(this, val), 10);
		return html;
	},
	_tabs_init() {
		$('.tabcontent').css({ display: 'none' });
		$('#tabs #tabbox .tab').click(APP.UI._tabs_settab);
	},
	_tabs_settab(e) {
		let id;
		if (typeof e === 'object')
			id = e.currentTarget.id;
		else
			id = e;
		if (id && !$(`#tabs #tabbox .tab#${id}`).hasClass('active')) {
			$('#tabs #tabbox .tab').removeClass('active');
			$(`#tabs #tabbox .tab#${id}`).addClass('active');
			const content = $(`.tabcontent#${id}`);
			//	console.log(content.data('for'));
			const html = content.html();
			$('.tabs #body').html(html);
			$(`.tabs #${content.data('for')}`).val(id).trigger('change');
		}
	},

	/**
	 * Draws a Tag Chooser
	 * @param  {string} id              [description]
	 * @param  {string} collection      [description]
	 * @param  {string} val             [description]
	 * @param  {String} [mode="single"] single | multi
	 * @param  {String} [classs=""]     [description]
	 * @return [type]                   [description]
	 */
	chooser(id, collection, val, mode = 'single', filter = {}) {
		let out = '';
		out += `<div class='tagchooser field' id='${id}' data-collection='${collection}' data-mode='${mode}' `;
		if (Object.keys(filter).length > 0)
			out += `data-filterkey='${Object.keys(filter)[0]}' data-filtervalue='${Object.values(filter)[0]}'`;

		out += '>';
		if (!_.isArray(val))
			val = [val];
		for (const v in val)
			if (val[v]) {
				const tag = APP.models[collection].get(val[v]);
				if (tag) {
					out += `<div id='${val[v]}' class='item'>`;
					out += `<span style='color:${tag.get('color') || 'black'}'>`;
					out += `${APP.Tools.icon(collection)}</span>`;
					out += ` ${tag.getName()}<div class='delete'>${APP.Tools.icon('delete')}</div></div>`;
				}
			}


		out += '</div>';
		return out;
	},

	/**
	 * A font-awesome picker
	 *
	 * this is getting complex enough that it should likely be a backbone view.
	 *
	 * @param  {string} id          Field ID
	 * @param  {string} val         Current value
	 * @param  {String} [target=""] #id of other field to also update (optional)
	 * @param  {String} [classs=""] Field classs
	 * @return html               the field
	 */
	fontawesome(id, val, target = '', extra = "class='field'") {
		let out = "<div class='fontawesomechooser'>";
		if (!target) {
			out += `<input type='hidden' id='${id}' value='${val}' ${extra}>`;
			target = id;
		}
		out += `<div class='preview field' id='${id}' `;
		out += 'onclick=\'console.log(document.getElementById("icons").style.display); ';
		out += 'this.parentElement.children.icons.style.display= ["","none"].indexOf(this.parentElement.children.icons.style.display)!=-1?"block":"none"\'>';
		//		out += "<div class='preview' id='" + id + "' onclick='$(\".fontawesomechooser #icons\").toggle();'>";
		if (val && val.indexOf('http') !== 0)
			out += APP.Tools.icon(val);
		out += '</div>';
		out += "<div id='icons'>";
		out += `<input type='search' autocomplete='off' id='search' placeholder='search' onkeyup='APP.UI._fa_search("${target}")'>`;
		out += "<div id='icon_list'>";
		out += APP.UI._fa_showicons(target);
		out += '</div>';
		out += '</div>'; // #icons
		out += '</div>'; // .fontawesomechooser
		return out;
	},
	_fa_showicons(target, search = false) {
		let out = '';
		const { solid } = require('font-awesome-icon-chars');
		const prevs = APP.Tools.store('fa', 'prevs') || [];
		if (prevs.length > 0)
		for (const p in prevs)
		if (!search || prevs[p].indexOf(search.toLowerCase()) >= 0)
		out += `<div class='tile' onclick='APP.UI._fa_select("${prevs[p]}", "${target.replace('"', '"')}")'>${APP.Tools.icon(prevs[p])}</div>`;
		
		const fa = solid;
		for (const f in fa)
			if (!search || fa[f].name.indexOf(search.toLowerCase()) >= 0)
				if (prevs.indexOf(fa[f].name) === -1) {
					out += `<div class='tile' onclick='APP.UI._fa_select("${fa[f].name}", "${target.replace('"', '"')}")'>${APP.Tools.icon(fa[f].name)}</div>`;
				}
		// console.log(fa[f].id);

		return out;
	},
	_fa_select(icon, target) {
		if (target.indexOf('#') === -1)
			target = `#${target}.field`;
		console.log(target, icon);
		$(target).parent().find('.preview').html(APP.Tools.icon(icon));
		if (target)
			$(target).val(icon).trigger('change');
		$('#icons').hide();
		const prevs = APP.Tools.store('fa', 'prevs') || [];
		prevs.unshift(icon);
		APP.Tools.store('fa', 'prevs', _.uniq(prevs));
	},
	_fa_search(target) {
		const search = $('.fontawesomechooser #search').val();
		const icons = APP.UI._fa_showicons(target, search);
		$('.fontawesomechooser #icons #icon_list').html(icons);
	}
};
