DEF = DEF || {};
DEF.TG = {};
const Highway = require('backbone.highway');

DEF.TG.Model = Backbone.Highway.Model.extend({
	idAttribute: '_id',

	// Fields used to draw the database view in DT
	db_columns    : ['_id'],
	// fields used as filterable values in database view in DT
	db_filters    : [],
	// fields to search on (defaults to db_columns)
	db_search     : [],
	// fields used in the Spreadsheet database view
	db_spreadsheet: [],
	// commands displayed below the filter row.  {label:"command",[...]}
	db_tools      : {},
	// list of fields that shouldnt be automatically created with the record,
	// but none-the-less are sometimes required.  Like "range_high"
	extra_defaults: {},

	// Fields that templates may need to .getUp()
	ups: [], // TODO maybe just use get_all_field or whatever.

	// Define the parent module, and the field used to hold the ID
	parentModule     : false, // name of parent module
	parentIdAttribute: false, // name of parent module's ID attribute

	// destroy: function (args) {
	// 	console.log("destroy");
	// 	Backbone.Highway.Model.prototype.destroy.apply(this, args);
	// },

	/**
	 * just like .get(), but uses parent fields if model fields are undefined
	 * @param  {string} field         Name of field
	 * @param  {string} parent_module Name of parent module
	 * @param  {id} parent_id     ID of parent module
	 * @return {string}               value of field
	 */
	getUp(field, parent_module, parent_id) {
		let val = null;
		if (!_.isUndefined(this.get(field)) && this.get(field) !== undefined && this.get(field) !== '')
			return this.get(field);
		parent_module = parent_module || this.parentModule;
		parent_id = parent_id || this.get(this.parentIdAttribute);
		const collection = APP.models[parent_module];
		if (collection) {
			const model = collection.get(parent_id);
			if (model)
				val = model.getUp(field);
		}
		if (val === null && typeof this.extra_defaults[field] !== 'undefined')
			val = this.extra_defaults[field];
		// console.warn(this.getName(), "default field applied", field, val);

		return val;
	},

	/**
	 * HTML-formatted version of the field
	 * @param  {[type]} field [description]
	 * @return {[type]}       [description]
	 */
	getf(field, value) {
		const prefix = APP.design_time ? '#DT/' : '#';
		if (value === undefined)
			value = this.getUp(field);
		if (value > 148969500000)
			return APP.Format.datetime(value);

		try {
			if (value)
				switch (field) {
				case '_id':
					return `<a href='${prefix}${this.collection_name}/${value}'>${APP.Tools.icon(this.collection_name)} ${APP.models[this.collection_name].get(value).getName()}</a>`;
				case 'poller_id':
					return `<a href='${prefix}pollers/${value}'>${APP.Tools.icon('pollers')} ${APP.models.pollers.get(value).getName()}</a>`;
				case 'd_id':
					return `<a href='${prefix}devices/${value}'>${APP.Tools.icon('devices')} ${APP.models.devices.get(value).getName()}</a>`;
				case 'dl_id':
					return `<a href='${prefix}device_library/${value}'>${APP.Tools.icon('device_library')} ${APP.models.device_library.get(value).getName()}</a>`;
				case 'tl_id':
					return `<a href='${prefix}tag_library/${value}'>${APP.Tools.icon('tag_library')} ${APP.models.tag_library.get(value).getName()}</a>`;
				case 't':
				case 't_id':
					return `<a href='${prefix}tags/${value}'>${APP.Tools.icon('tags')} ${APP.models.tags.get(value).getName()}</a>`;
				case 'address':
					return ` ${value}`;
				case 'master_id':
				case 'screen_id':
					return `<a href='${prefix}screens/${value}'>${APP.Tools.icon('screens')} ${APP.models.screens.get(value).getName()}</a>`;
				case 'image_url':
					return `<img src='${this.get('image_url')}'>`;
				}
		} catch (err) {
			if (field !== 'poller_id') // pollers shouldnt delete all models associated with it
				if (!this.get('ORPHAN')) {
					console.warn('BAD ID', this.collection_name, this.getName(), field, value);
					this.set('ORPHAN', (this.get('ORPHAN') || 1) + 1);
				}

			//			APP.models[this.collection_name].remove()
			// _.delay(function(collection, id) {
			// 	console.log("removing " + id);
			// 	collection.remove(id);
			//
			// }.bind(this, this.collection, this.id), 1000);
		}
		return value;
	},

	// Returns an object with all the getUp'd values.  Use as a templateHelper
	//  TODO seems like i already made this kind of function
	//  TODO SerializeData might be a better thing here.
	getUps() {
		const out = {};
		for (let u = 0; u < this.ups.length; u++) {
			const field = this.ups[u];
			out[field] = this.getUp(field);
		}
		return out;
	},
	FindParent(collection, id) {
		const key = collection + id;
		if (id) {
			if (this._findparent && this._findparent[key])
				// console.count('findparent');
				return this._findparent[key];

			let model;
			// if (typeof id === 'object')
			// 	model = id;
			// else
			model = APP.models[collection].get(id);
			if (model) {
				this[collection] = model;
				if (!this._findparent)
					this._findparent = {};
				this._findparent[key] = model;
				return model;
			}
			console.warn(`'${this.getName()}'`, `Cannot find ${collection}`, id);
			this.set('ORPHAN', (this.get('ORPHAN') || 1) + 1);
			model = new DEF[collection].Model({ _id: id });
			return model;
		}
		return false;
	},

	/**
	 * Override me to return the most common identifiable name of the record
	 * @return {string} the name
	 */
	getName() {
		return this.get('_id');
	},

	/**
	 * Override me to return a simple description
	 * @return {string} the desc
	 */
	getDesc() {
		return '';
	},
	getIcon() {
		return APP.Tools.icon(this.collection_name);
	},

	/**
	 * return a searchable string
	 */
	getSearch() {
		const out = [this.getName()];
		const search = this.db_search.length ? this.db_search : this.db_columns;
		for (const s in search)
			out.push(this.getUp(search[s]));

		return out.join(' ').toUpperCase();
	},
	getAlarmState() {
		return 'normal';
	},

	/**
	 * Shortcut for setting status of items
	 * @param {bool} online      Is the thing online/working
	 * @param {string} state       1-word description of whats happeneing
	 * @param {string} status_text Description of what happened
	 * @param {object} extras      a hash of extra attributes that are also written
	 */
	SetStatus(online, state, status_text, extras) {
		if (_.isObject(status_text))
			status_text = status_text[Object.keys(status_text)[0]];

		let json = {
			state        : state ? state.toUpperCase() : '',
			online       : !!online,
			state_updated: Date.now(),
			status       : status_text
		};
		if (extras)
			json = _.extend(json, extras);
		// console.log(json);
		this.set(json);
	},

	/**
	 * Returns a list of this model's attributes, suitable for inserting into collection.
	 * Each model should alter it slightly, such as adding "copy" to the name, or shifting the
	 * coordiantes.
	 * @return {[type]} [description]
	 */
	GetCloneAttributes() {
		const attr = JSON.parse(JSON.stringify(this.attributes));
		return attr;
	},
	all_fields() {
		return this.attributes;
	}
});

DEF.TG.Collection = Backbone.Highway.Collection.extend({
	// get: function (args) {
	// 	var model = Backbone.Highway.Collection.prototype.get.call(this, args);
	// 	// Handle missing tags.  #2.8.12
	// 	if (APP.models.tags.is_synced && typeof model == "undefined") {
	// 		return new DEF.tags.Model({
	// 			tag_name: "NOTAG"
	// 		});
	// 	}
	// 	return model;
	// }

});


// MODELS
require('../models/settings/model.js');
require('../models/device_library/model.js');
require('../models/tag_library/model.js');
require('../models/device/model.js');
require('../models/tag/model.js');
require('../models/user/model.js');
require('../models/widget/model.js');
require('../models/screen/model.js');
require('../models/poller/model.js');
require('../models/data/model.js');
require('../models/report/model.js');
require('../models/files/model.js');
require('../models/events/model.js');
require('../models/network/model.js');
