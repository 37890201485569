module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var bands = bands || false;

__p+='\n<table>\n	<tr>\n		<th>Title</th>\n		<td>\n			<input type=\'text\' id=\'title\' value=\''+
((__t=(title))==null?'':__t)+
'\' class=\'field\'>\n		</td>\n	</tr>\n	<tr>\n		<td>LED Status Tag</td>\n		<td>\n			'+
((__t=(APP.UI.chooser("led", "tags", led)))==null?'':__t)+
'\n		</td>\n	<tr>\n	<tr>\n		<th>Labels</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("display", {"symbol":"Symbol","tag_name":"Tag Name","name":"Name","prefix":"Prefix"},display, "field") ))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Filter</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("filter", {"":"All","number":"Number","boolean":"Boolean","string":"String","date":"Date"},filter, "field") ))==null?'':__t)+
'\n		</td>\n	</tr>\n	<!-- <td colspan=2>\n				<hr>\n			</td> -->\n	</tr>\n	<!-- <tr>\n			<th>By Device</th>\n			<td>\n				'+
((__t=(APP.UI.chooser("d_id", "devices", d_id)))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<th>By Device Library</th>\n			<td>\n			</td>\n		</tr>\n</table>\n<fieldset>\n	<legend>Columns</legend>\n	<table>\n		<tr>\n			<td>\n				<input type=\'checkbox\' id=\'bars\' value=\'true\' '+
((__t=(bars? "checked": ""))==null?'':__t)+
' class=\'field live\'> Value Bars\n			</td>\n			<td>\n				<input type=\'checkbox\' id=\'deviation\' value=\'true\' '+
((__t=(deviation? "checked": ""))==null?'':__t)+
' class=\'field live\'> Deviation Bars\n			</td>\n		</tr>\n		<tr>\n			<td>\n				<input type=\'checkbox\' id=\'values\' value=\'true\' '+
((__t=(values? "checked": ""))==null?'':__t)+
' class=\'field live\'> Values\n			</td>\n			<td>\n				<input type=\'checkbox\' id=\'units\' value=\'true\' '+
((__t=(units? "checked": ""))==null?'':__t)+
' class=\'field live\'> Units\n			</td>\n		</tr>\n	</table>\n\n</fieldset> -->\n\n</table>\n\n<!-- <hr> -->\n'+
((__t=(APP.UI.tabs("mode",mode,{bydev:"Device",bydevlib:"Device Library",bytag:"Tags"}) ))==null?'':__t)+
'\n\n<div class=\'tabcontent\' data-for="mode" id=\'bytag\'>\n	'+
((__t=(APP.UI.chooser("t_ids", "tags", t_ids,"multi")))==null?'':__t)+
'\n	<table>\n		<tr>\n			<td>\n				<input type=\'checkbox\' id=\'bars\' value=\'true\' '+
((__t=(bars? "checked": ""))==null?'':__t)+
' class=\'refresh field live\'> Value\n				Bars\n				<div style=\'display:'+
((__t=(bars?"block":"none"))==null?'':__t)+
'\'>\n					&nbsp;&nbsp;<input type=\'checkbox\' id=\'bands\' value=\'true\' '+
((__t=((bands)? "checked": ""))==null?'':__t)+
'\n						class=\'field live\'>&hellip;with Alarm Bands\n				</div>\n			</td>\n			<td>\n				<input type=\'checkbox\' id=\'deviation\' value=\'true\' '+
((__t=(deviation? "checked": ""))==null?'':__t)+
' class=\'field live\'>\n				Deviation Bars\n			</td>\n		</tr>\n		<tr>\n			<th>Trend Arrows</th>\n			<td>\n				'+
((__t=(APP.UI.select_from_object('arrow',{'none':'none','delta':'Last Read', 'delta_1m':'1 Minute Ago', 'delta_1h':'1 Hour Ago'}, arrow)))==null?'':__t)+
'\n			</td>\n		</tr>\n	</table>\n</div>\n\n<div class=\'tabcontent\' data-for="mode" id=\'bydev\'>\n	'+
((__t=(APP.UI.chooser("d_id", "devices", d_id)))==null?'':__t)+
'\n\n	<table>\n		<tr>\n			<td>\n				<input type=\'checkbox\' id=\'bars\' value=\'true\' '+
((__t=(bars? "checked": ""))==null?'':__t)+
' class=\'refresh field live\'> Value\n				Bars\n				<div style=\'display:'+
((__t=(bars?"block":"none"))==null?'':__t)+
'\'>\n					&nbsp;&nbsp;<input type=\'checkbox\' id=\'bands\' value=\'true\' '+
((__t=((bands)? "checked": ""))==null?'':__t)+
'\n						class=\' field live\'>&hellip;with Alarm\n					Bands\n				</div>\n			</td>\n			<td>\n				<input type=\'checkbox\' id=\'deviation\' value=\'true\' '+
((__t=(deviation? "checked": ""))==null?'':__t)+
' class=\'field live\'>\n				Deviation Bars\n			</td>\n		</tr>\n		<tr>\n			<td>\n				<input type=\'checkbox\' id=\'values\' value=\'true\' '+
((__t=(values? "checked": ""))==null?'':__t)+
' class=\'field live\'> Values\n			</td>\n			<td>\n				<input type=\'checkbox\' id=\'units\' value=\'true\' '+
((__t=(units? "checked": ""))==null?'':__t)+
' class=\'field live\'> Units\n			</td>\n		</tr>\n		<tr>\n			<th>Trend Arrows</th>\n			<td>\n				'+
((__t=(APP.UI.select_from_object('arrow',{'none':'none','delta':'Last Read', 'delta_1m':'1 Minute Ago', 'delta_1h':'1 Hour Ago'}, arrow)))==null?'':__t)+
'\n			</td>\n		</tr>\n	</table>\n\n</div>\n<div class=\'tabcontent\' data-for="mode" id=\'bydevlib\'>\n	'+
((__t=(APP.UI.chooser("dl_id", "device_library", dl_id)))==null?'':__t)+
'\n\n	<table>\n		<tr>\n			<td>\n				<input type=\'checkbox\' id=\'units\' value=\'true\' '+
((__t=(units? "checked": ""))==null?'':__t)+
' class=\'field live\'> Units\n			</td>\n		</tr>\n	</table>\n\n\n</div>';
}
return __p;
};
