DEF.layout.Login = Backbone.Marionette.View.extend({
	id: 'LOGIN',
	template: require('./templates/login.html'),
	ui: {
		email: '#email',
		id: '#login_id',
		password: '#password',
		login: '#login',
		link: '#link',
		message: '#message',
	},
	regions: {
		userlist: '#userlist_box',
	},
	events: {
		'click @ui.login': 'Login',
		'click @ui.link': 'SendLink',
	},
	childViewEvents: {
		'select:item': "ClickIcon"
	},
	onRender() {
		const list = new DEF.users.DT.Main({
			collection: APP.models.users,
			childView: DEF.users.DT.child,
		})
		this.showChildView('userlist', list)
		// $('html').css('position', 'initial');
		// console.log('pos', $('html').css('position'));
	},
	ClickIcon(childView) {
		console.log('user click', childView)
		const id = childView.model.id
		$('#login_id').val(id)
		$('.user').removeClass('selected')
		$(`#${childView.model.id}.user`).addClass('selected')
		// $('#footer').slideUp();
		$('#password').prop('disabled', false).removeClass('disabled').focus()
		let hint = APP.models.users.get(id).get('password_hint')
		hint = hint && hint !== 'encrypted' ? `Password Hint: ${hint}` : ''
		$('#password').prop('placeholder', hint)
		$('#message').html('')
		$('#password').val('')
	},
	Login() {
		console.log(this.ui.id.val(), this.ui.password.val())
		const user = APP.models.users.findWhere({
			_id: this.ui.id.val(),
		})
		if (user) {
			const hash = user.get('password')
			const bcrypt = require('bcryptjs')
			console.log(hash, this.ui.password.val())
			if (bcrypt.compareSync(this.ui.password.val(), hash)) {
				this.Pass(user)
				user.set('last_login', Date.now())
			} else {
				this.ui.message.html(
					`${APP.Tools.icon('exclamation-triangle')} Invalid Password.${
						user.get('password_hint')
							? `  Hint: ${user.get('password_hint')}`
							: ''
					}`
				)
			}
		} else {
			this.ui.message.html(
				`${APP.Tools.icon('exclamation-triangle')} Invalid User.`
			)
		}
	},
	Pass(user) {
		APP.USER = user
		APP.Tools.store('auth', 'uid', user.id)
		const root = new DEF.layout.Splash({})
		APP.root.showChildView('main', root)
		APP.Start()
	},
	SendLink() {
		const user = APP.models.users.findWhere({
			_id: this.ui.id.val(),
		})
		if (user) {
			const templ = require('../models/user/templates/link_email.html')
			const options = {
				uid: user.id,
				name: SETTINGS.name,
				email: user.get('email'),
				homepage: SETTINGS.posturl,
				logo: SETTINGS.logo_url
					? `${SETTINGS.posturl}/${SETTINGS.logo_url}`
					: 'http://www.telegauge.com/static/img/logo%20with%20text.744dfe5.png',
			}
			const email = templ(options)
			const to = user.get('email')
			const subj = `${SETTINGS.name} Login Link`
			APP.Tools.SendMail(to, subj, email, (err, resp, body) => {
				this.$el.find('#message').html(body)
				console.log('ERR', err)
				console.log('RESP', resp)
				console.log('BODY', body)
			})
		}
		this.ui.message.html(`Sending emailed to ${user.getName()}`)
	},
})
